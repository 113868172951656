import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { getContactNotes, getJobNotes } from "Utils";
import NotFound from "assets/svg/not-found.svg";
import Searchicon from "assets/svg/search-icon.svg";
import axios from "axios";
import config from "config";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as Loadar } from "../../../views/Dashboard/Loader";
import "../../../views/Dashboard/org.css";
import AddNote from "./AddNote";
import { useCookies } from "react-cookie";
import { userActivity } from "Utils";
import moment from "moment";
const NoteModel = ({
  jobNote,
  handleJobNoteClose,
  note,
  setNote,
  searchNote,
  getnotes,
  setGetNotes,
  setSearchNote,
  getJobpostdata,
  type,
  contactUrl,
  submissionData,
  setNotesLoading,
  notesLoading,
  page,
  rowPerPage
}) => {
  const [cookie] = useCookies(["role", "id", "token"]);

  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;
  const [editedNoteIndex, setEditedNoteIndex] = useState(-1);
  const [noteSubmitLoading, setNoteSubmitLoading] = useState(false);

  const handleNoteDelete = async (id, valueType) => {
    let url;
    if (valueType === "job") {
      url = `${config?.apiUrl}/api/delete_job_Note/${id}`;
    } else if (valueType === "contact") {
      url = `${config?.apiUrl}/api/delete_Contact_Note/${id}`;
    }

    await axios
      .delete(url)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });

        const noteData = response?.data;
        const nameofJobConatct = noteData?.accountOwnerName
          ? noteData?.accountOwnerName
          : noteData?.jobtitle;
        const nameofJobConatctComponent = noteData?.accountOwnerName
          ? "contact"
          : "job";

        const activityOn = `Delete note in ${nameofJobConatctComponent} for ${nameofJobConatct}`;
        const activityDo = `Delete note in ${nameofJobConatctComponent}`;
        const component = "note";
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole
        );
        if (valueType === "job") {
          getJobNotes(note.jobId, setGetNotes, setNotesLoading);
        } else if (valueType === "contact") {
          getContactNotes(note.contactId, setGetNotes, setNotesLoading);
        }
        // submissionData?.accountName
        //   ? getJobpostdata(0, 10, submissionData?.accountName)
        //   : getJobpostdata();
        submissionData?.accountName
          ? getJobpostdata("", page || 0,rowPerPage || 0)
          : getJobpostdata("", page || 0, rowPerPage ||0 );
      })
      .catch((err) => {
        toast.error(err.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleEditNoteChange = (value, index) => {
    setGetNotes((prevNotes) => {
      const newNotes = [...prevNotes];
      if (newNotes[index].jobNote) {
        newNotes[index].jobNote = value;
      } else {
        newNotes[index].contactNote = value;
      }
      return newNotes;
    });
  };

  const handleNoteChange = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setNote({ ...note, [name]: value });
  };

  const handleEditNotes = (index) => {
    setEditedNoteIndex(index);
  };

  const handleCancelEdit = () => {
    setEditedNoteIndex(-1);
  };

  const handleSaveNoteEdit = async (id) => {
    const login_firstName = localStorage?.getItem("firstname");
    const login_lastName = localStorage?.getItem("lastname");
    const editedNote = getnotes[editedNoteIndex];
    const { jobNote, contactNote } = editedNote;
    const url = jobNote
      ? `${config?.apiUrl}/api/update_Job_Note/${id}`
      : `${config?.apiUrl}/api/update_Contact_Note/${id}`;

    const credentials = jobNote
      ? {
          jobNote,
          noteSubmitter: `Updated by ${login_firstName} ${login_lastName}`,
          updated_at: new Date().getTime(),
        }
      : {
          contactNote,
          noteSubmitter: `Updated by ${login_firstName} ${login_lastName}`,
          updated_at: new Date().getTime(),
        };
    setNoteSubmitLoading(true);
    await axios
      .put(url, credentials)
      .then((result) => {
        toast.success(result.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setNoteSubmitLoading(false);
        setEditedNoteIndex(-1);
        const noteData = result?.data;
        const nameofJobConatct = noteData?.accountOwnerName
          ? noteData?.accountOwnerName
          : noteData?.jobtitle;
        const nameofJobConatctComponent = noteData?.accountOwnerName
          ? "contact"
          : "job";

        const activityOn = `Update note in ${nameofJobConatctComponent} for ${nameofJobConatct}`;
        const activityDo = `Update note in ${nameofJobConatctComponent}`;
        const component = "note";
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole
        );
        note.jobId
          ? getJobNotes(note.jobId, setGetNotes, setNotesLoading)
          : getContactNotes(note.contactId, setGetNotes, setNotesLoading);
      
      })
      .catch((err) => {
        setNoteSubmitLoading(false);
        toast.error(err.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleJobNoteSubmit = async () => {
    const login_firstName = localStorage?.getItem("firstname");
    const login_lastName = localStorage?.getItem("lastname");
    if (!note.jobNote && !note?.contactNote) {
      toast.error("Please Enter Note", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setNoteSubmitLoading(true);
      const url = contactUrl ? contactUrl : "/api/Set_Job_Note";
      const data = contactUrl
        ? {
            contactId: note.contactId,
            contactNote: note.contactNote,
            noteSubmitter: `Created by ${login_firstName} ${login_lastName}`,
            created_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          }
        : {
            jobId: note.jobId,
            jobNote: note.jobNote,
            noteSubmitter: `Created by ${login_firstName} ${login_lastName}`,
            created_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          };

      await axios
        .post(`${config?.apiUrl}${url}`, data)
        .then((result) => {
          toast.success(result.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setNoteSubmitLoading(false);
          document.getElementById("notes_list").style.display = "block";
          document.getElementById("add_note").style.display = "none";
          setNote({
            [contactUrl ? "contactNote" : "jobNote"]: contactUrl ? "" : "",
            [contactUrl ? "contactId" : "jobId"]: contactUrl
              ? note.contactId
              : note.jobId,
          });

          const noteData = result?.data;
          const nameofJobConatct = noteData?.accountOwnerName
            ? noteData?.accountOwnerName
            : noteData?.jobtitle;
          const nameofJobConatctComponent = noteData?.accountOwnerName
            ? "contact"
            : "job";

          const activityOn = `Add new note in ${nameofJobConatctComponent} for ${nameofJobConatct}`;
          const activityDo = `Add new note in ${nameofJobConatctComponent}`;
          const component = "note";
          userActivity(
            candidate_ownerName,
            userId,
            component,
            activityDo,
            activityOn,
            userRole
          );

          note?.jobId && getJobNotes(note.jobId, setGetNotes, setNotesLoading);
          note?.contactId &&
            getContactNotes(note.contactId, setGetNotes, setNotesLoading);
          // submissionData?.accountName
          //   ? getJobpostdata(0, 10, submissionData?.accountName)
          //   : getJobpostdata();
          submissionData?.accountName
            ? getJobpostdata("", page || 0, rowPerPage || 0)
            : getJobpostdata("", page || 0, rowPerPage || 0);
          
        })
        .catch((err) => {
          setNoteSubmitLoading(false);
          toast.error(err || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleCloseAddNotes = () => {
    document.getElementById("add_note").style.display = "none";
    document.getElementById("notes_list").style.display = "block";
    setNote({
      [contactUrl ? "contactNote" : "jobNote"]: contactUrl ? "" : "",
      [contactUrl ? "contactId" : "jobId"]: contactUrl
        ? note.contactId
        : note.jobId,
    });
  };

  const handleAddNotes = () => {
    document.getElementById("add_note").style.display = "block";
    if (getnotes.length == 0) {
      document.getElementById("notes_list").style.display = "none";
    } else {
      document.getElementById("notes_list").style.display = "block";
    }
  };

  const filterNotesData = (string = "") => {
    if (string.length !== 0) {
      var filternote = getnotes?.filter((value) => {
        if (value.contactNote) {
          return searchNote.toLowerCase() === ""
            ? true
            : value.contactNote
                .toLowerCase()
                .includes(searchNote.toLowerCase());
        } else if (value.jobNote) {
          return searchNote.toLowerCase() === ""
            ? true
            : value.jobNote.toLowerCase().includes(searchNote.toLowerCase());
        }
        return false;
      });
      setGetNotes(filternote);
    } else {
      if (note?.jobId) getJobNotes(note?.jobId, setGetNotes, setNotesLoading);
      if (note?.contactId)
        getContactNotes(note?.contactId, setGetNotes, setNotesLoading);
    }
  };

  return (
    <>
      <div className="model-box-view">
        <Modal
          show={jobNote}
          onHide={handleJobNoteClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box>
              <Row>
                <Col lg={6} md={6}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <img src={Searchicon} width="18px" height="18px" />
                      }
                    />
                    <Input
                      variant="auth"
                      fontSize="sm"
                      type="text"
                      placeholder="Search With Keyword"
                      value={searchNote}
                      onChange={(e) => {
                        setSearchNote(e.target.value);
                        filterNotesData(e.target.value);
                      }}
                      mb="24px"
                      size="lg"
                      style={{ height: "37px" }}
                    />
                  </InputGroup>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "36px",
                  }}
                >
                  <button className="addnote_btn" onClick={handleAddNotes}>
                    <AddIcon /> Add Note
                  </button>
                </Col>
              </Row>
            </Box>
            <Box id="add_note">
              <Box>
                
                <Textarea
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="text"
                  placeholder="Type Note..."
                  mb="24px"
                  size="lg"
                  name={contactUrl ? "contactNote" : "jobNote"}
                  value={contactUrl ? note?.contactNote : note.jobNote}
                  onChange={handleNoteChange}
                  style={{ border: "1px solid #ececec", height: "163px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button className="cancel" onClick={handleCloseAddNotes}>
                  Cancel
                </Button>
                <Button
                  className="addnote_btn"
                  onClick={() => {
                    handleJobNoteSubmit();
                  }}
                  isDisabled={noteSubmitLoading}
                  isLoading={noteSubmitLoading}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Box id="notes_list">
              {notesLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Loadar />
                </Box>
              ) : getnotes?.length == 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={NotFound}
                      alt="NotFound"
                      style={{ width: "16rem" }}
                    />
                    <p>No Records Found! Please try again....</p>
                  </div>
                </>
              ) : (
                <>
                  <AddNote
                    handleSaveNoteEdit={handleSaveNoteEdit}
                    getnotes={getnotes}
                    handleEditNotes={handleEditNotes}
                    handleNoteDelete={handleNoteDelete}
                    handleEditNoteChange={handleEditNoteChange}
                    editedNoteIndex={editedNoteIndex}
                    handleCancelEdit={handleCancelEdit}
                    noteSubmitLoading={noteSubmitLoading}
                  />
                </>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel" onClick={handleJobNoteClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NoteModel;
