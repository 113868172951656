import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, lazy, useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch,useHistory } from "react-router-dom";
import theme from "theme/theme.js";
import AdminLayout from "../src/layouts/Admin";
import DetailsView from "layouts/DetailsView";
import SignIn from "views/Pages/SignIn";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  useGetUsersNamesQuery,
  useLazyGetUsersNamesQuery,
  useLazyGetUserTeamDataQuery,
} from "./redux/api/users";


// const SignIn = lazy(() => import("views/Pages/SignIn"));
const SignUp = lazy(() => import("views/Pages/SignUp"));
const Forgotpass = lazy(() => import("views/Pages/Forgotpass"));
const Changepass = lazy(() => import("views/Pages/Changepass"));
// const Candidateview = lazy(() => import("views/Dashboard/Candidateview"));
// const Accountview = lazy(() => import("views/Dashboard/Accountview"));
// const Jobpostview = lazy(() => import("views/Dashboard/Jobpostview"));
// const Contactview = lazy(() => import("views/Dashboard/Contactview"));
// const Submissionview = lazy(() => import("views/Dashboard/Submissionview"));
const TotalJobs = lazy(
  () => import("views/Dashboard/Report_Filters/TotalJobs")
);
const TotalActiveJobs = lazy(
  () => import("views/Dashboard/Report_Filters/TotalActiveJobs")
);
const TotalSubmission = lazy(
  () => import("views/Dashboard/Report_Filters/TotalSubmission")
);
const TotalJobWithNoSubmission = lazy(
  () => import("views/Dashboard/Report_Filters/TotalJobWithNoSubmission")
);
const TotalCandidates = lazy(
  () => import("views/Dashboard/Report_Filters/TotalCandidates")
);
const TotalSelectedCandidates = lazy(
  () => import("views/Dashboard/Report_Filters/TotalSelectedCandidates")
);
const TotalJoinedCandidates = lazy(
  () => import("views/Dashboard/Report_Filters/TotalJoinedCandidates")
);
const TotalPipelined = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalPipeline")
);
const TotalSubmitted = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalSubmitted")
);
const TotalInterviewed = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalInterviewed")
);
const TotalSelectedCandidate = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalSelectedCandidate")
);
const TotalJoinedCandidate = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalJoinedCandidates")
);
const TotalOnboardedCandidate = lazy(
  () =>
    import("views/Dashboard/JobTotalViewComponents/TotalOnboardedCandidates")
);
const TotalJob = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalJobs")
);
const TotalActiveJob = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalActiveJob")
);
const TotalDeactiveJob = lazy(
  () => import("views/Dashboard/JobTotalViewComponents/TotalDeactiveJob")
);
const Profile = lazy(() => import("views/Dashboard/Profile"));

// const CreateForm = lazy(() => import("views/Dashboard/CreateForm"));
const Form = lazy(() => import("views/Dashboard/Form"));
// const SelectUserRoles = lazy(
//   () => import("components/Commoncomponent/Securitycomponent/SelectUserRoles")
// );

const Loading = () => {
  return (
    <div className="loading">
      <span className="main_loader"></span>
    </div>
  );
};

const App = () => {
  const [cookies] = useCookies(["token",'id']);
  const [role] = useCookies(["role"]);
  const [trigger] = useLazyGetUsersNamesQuery();
  const history = useHistory()
  const [temaDatatrigger] = useLazyGetUserTeamDataQuery();
  const isLogin = cookies?.token;

  useEffect(() => {
    if (isLogin) {
      trigger();
      temaDatatrigger(cookies?.id);
      if (location.pathname === "/") {
        history.push("/admin/dashboard");
      }
    }
  }, [isLogin]);

  return (
    <ChakraProvider theme={theme} resetCss={false} position="relative">
     
        <Switch>
          <Route
            path={`/admin/total_jobs`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalJobs />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_active_jobs`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalActiveJobs />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_submission`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalSubmission />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_job_with_no_submission`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalJobWithNoSubmission />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_candidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalCandidates />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_selected_candidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalSelectedCandidates />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_joined_candidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalJoinedCandidates />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Pipelined`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalPipelined />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Submitted`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalSubmitted />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Interviewed`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalInterviewed />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_SelectedCandidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalSelectedCandidate />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_JoinedCandidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalJoinedCandidate />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_OnboardedCandidates`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalOnboardedCandidate />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Job`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalJob />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Active_Job`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalActiveJob />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/total_Deactive_Job`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TotalDeactiveJob />
              </Suspense>
            )}
          />
          <Route
            path={`/admin/profile`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            )}
          />
          {/* <Route
            path={`/organizationview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Organizationview />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path={`/submissionview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Submissionview />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path={`/candidateview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Candidateview />
              </Suspense>
            )}
          /> */}

          {/* <Route
            path={`/accountview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Accountview />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path={`/jobpostview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Jobpostview />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path={`/contactview/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Contactview />
              </Suspense>
            )}
          /> */}
          <Route
            path={`/forgot`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Forgotpass />
              </Suspense>
            )}
          />
          <Route
            path={`/changepass`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <Changepass />
              </Suspense>
            )}
          />
          <Route
            path={`/signup`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <SignUp />
              </Suspense>
            )}
          />
          <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/details`} component={DetailsView} />
          {/* <Route
            path={`/CreateForm`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <CreateForm />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path={`/editform/:id`}
            render={() => (
              <Suspense fallback={<Loading />}>
                <CreateForm />
              </Suspense>
            )}
          /> */}
          <Route
            path="/form/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Form />
              </Suspense>
            )}
          />
          {/* <Route
            path="/SelectUserRoles"
            render={() => (
              <Suspense fallback={<Loading />}>
                <SelectUserRoles />
              </Suspense>
            )}
          /> */}
          {/* {!isLogin ? (
            <Route path={`/admin`} component={AdminLayout} />
          ) : (
            <Route path={`/`} component={AdminLayout} />
          )} */}
          <Route path={`/`} render={() => <SignIn />} />
        </Switch>
 
    </ChakraProvider>
  );
};

ReactDOM.render(
  <CookiesProvider>
     <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
);
