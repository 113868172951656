import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { userActivity } from "Utils";
import NotFound from "assets/svg/not-found.svg";
import axios from "axios";
import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import Loader from "./Loader";
import Submissionform from "./Submissionform";
import "./org.css";

function Submissionview() {
  const history = useHistory();
  const handleGoBack = () => {
    history?.goBack();
  };
  const [Id, setId] = useState("");
  const [cookie] = useCookies(["role", "id"]);

  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;

  const [loading, setLoading] = useState(true);
  const [displaysubmission, setDisplaySubmission] = useState([]);
  const [objectURL, setObjectURL] = useState("");
  const [submissionPreview, SetSubmissionPreview] = useState(false);
  let { id } = useParams();
  const [parseAge, setParseAge] = useState("");
  const [parsePercentage, setParsePercentage] = useState("");
  const [matchingSkills, setMatchingSkills] = useState([]);
  const [missingSkills, setMissingSkills] = useState([]);
  const [ParsedSkills, setParsedSkills] = useState([]);
  const [jobpost, setJobpost] = useState([]);
  const [loadingResumeScreening, setLoadingResumeScreening] = useState(false);
  const [parseResumeConclusion, setparseResumeConclusion] = useState("");
  const parts = location.pathname.split("/");
  const router = parts[parts.length - 1];
  const [reumeid, setReumeId] = useState(false);
  const [submission, setSubmission] = useState({
    jobId: "",
    clientId: "",
    candidateId: "",
    Questions: "",
    email: "",
    clientemail: "",
    status: "",
    currentCandidateCTCType: "",
    currentCandidateCTC: "",
    expectedCandidateCTCType: "",
    expectedCandidateCTC: "",
    noticePeriod: "",
    relevantExperiance: "",
    availability: "",
    engagement: "",
    locationPreference: "",
    QnA: [],
    file: "",
    fileName: "",
    filePath: "",
  });
  const [submissionId, setSubmissionId] = useState(false);
  const [editSubmission, SetEditSubmission] = useState(false);
  const [activity, SetActivity] = useState([]);
  const [candidateid, setCandidateId] = useState("");

  const getCandidateActivity = (id) => {
    axios
      .get(`${config?.apiUrl}/api/get_Candidateactivities/${candidateid}`)
      .then((response) => {
        const activitydata = response.data.data;
        SetActivity(activitydata);
      })
      .catch((err) => {
        toast.error(
          "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleEditShow = () => {
    SetEditSubmission(true);
    setSubmission(true);
  };
  const handleEditClose = () => {
    SetEditSubmission(false);
    setId("");
  };

  const handleInputs = (e) => {
    let name, value;
    name = e?.target?.name;
    value = e?.target?.value;
    if (name && value) {
      setSubmission({ ...submission, [name]: value });
    } else {
      setSubmission({});
    }
  };

  const saveFile = (e) => {
    setSubmission({
      ...submission,
      fileName: e.target.files[0].name,
      filePath: e.target.files[0].path,
      file: e.target.files[0],
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const {
      currentCandidateCTCType,
      currentCandidateCTC,
      expectedCandidateCTCType,
      expectedCandidateCTC,
      noticePeriod,
      relevantExperiance,
      availability,
      engagement,
      locationPreference,
      status,
    } = submission;

    const formData = new FormData();
    formData.append("file", submission?.file);
    formData.append("fileName", submission?.fileName);
    formData.append("filePath", submission?.filePath);
    formData.append("currentCandidateCTCType", currentCandidateCTCType);
    formData.append("currentCandidateCTC", currentCandidateCTC);
    formData.append("expectedCandidateCTCType", expectedCandidateCTCType);
    formData.append("expectedCandidateCTC", expectedCandidateCTC);
    formData.append("noticePeriod", noticePeriod);
    formData.append("relevantExperiance", relevantExperiance);
    formData.append("availability", availability);
    formData.append("engagement", engagement);
    formData.append("locationPreference", locationPreference);
    formData.append("status", status);
    formData.append(
      "candidate_ownerName",
      localStorage?.getItem("candidate_ownerName")
    );
    formData.append("userId", cookie.id);

    let configdata = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const url = `${config?.apiUrl}/api/appliedjob_update/${submissionId}`;

    await axios
      .put(url, formData, configdata)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleEditClose();
        getSubmission(id);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleResumeMatching = async (e) => {
    setLoadingResumeScreening(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", submission?.file);
    formData.append("fileName", submission?.fileName);
    formData.append("filePath", submission?.filePath);
    formData.append("jobRequirement", JSON.stringify(jobpost));
    formData.append("requiredSkill", submission?.skill);

    await axios
      .post(`${config?.apiUrl}/api/resume_screening`, formData)
      .then((result) => {
        toast.success(result.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        const pasreConclusion = result.data.data?.conclusion;
        setLoadingResumeScreening(false);
        const requiredSkill = submission?.skill || submission?.candidateId?.skill;
        const rdata = JSON.parse(result.data.data?.jsonResponse);
        const pAge = rdata?.age;
        setParseAge(pAge);
        const percentMatch = result.data.data?.percentageMatch;
        setParsePercentage(percentMatch);
        let parsedSkills = Array.isArray(rdata.skills)
          ? rdata.skills
          : rdata.skills.split(",");

        const requiredSkillsArray = requiredSkill?.split(",");

        const matchedSkills = parsedSkills?.filter((parsedSkill) => {
          return requiredSkillsArray.some((reqSkill) => {
            // Check if the beginning of any word in parsedSkill matches the beginning of reqSkill
            const parsedWords = parsedSkill.toLowerCase().split(" ");
            const reqWords = reqSkill.toLowerCase().split(" ");

            return parsedWords.some((parsedWord) =>
              reqWords.some(
                (reqWord) =>
                  parsedWord.startsWith(reqWord) ||
                  reqWord.startsWith(parsedWord)
              )
            );
          });
        });

        const missingSkills = requiredSkillsArray?.filter(
          (reqSkill) =>
            !matchedSkills.some(
              (matchedSkill) =>
                matchedSkill.toLowerCase().startsWith(reqSkill.toLowerCase()) ||
                reqSkill.toLowerCase().startsWith(matchedSkill.toLowerCase())
            )
        );

        setMatchingSkills(matchedSkills);
        setMissingSkills(missingSkills);
        setParsedSkills(parsedSkills);
        setparseResumeConclusion(pasreConclusion);
        setLoading(false);
        setLoadingResumeScreening(false);
        document.getElementsByClassName("matched_skill")[0].style.display =
          "block";
        document.getElementsByClassName("conclusion")[0].style.display =
          "block";
        if (matchedSkills.length === 0) {
          toast.error("You don't match the required skills.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 404) {
          // Display toaster for file not found in the database
          toast.error("Resume file not found in the database.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingResumeScreening(false);
        } else {
          // Display a general error toaster
          toast.error(
            "Please check your resume or try again later. If the issue persists, please add your resume.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setLoadingResumeScreening(false);
        }
        setLoading(false);
        setLoadingResumeScreening(false);
      });
  };
  const getSubmission = (id) => {
    axios
      .get(`${config?.apiUrl}/submissionview/${id}`)
      .then((response) => {
        const submissiondata = response.data.data;
        setSubmission(submissiondata);
        setDisplaySubmission(submissiondata);
        const candidateId = submissiondata?.candidateId?._id;

        setCandidateId(candidateId);
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmissionPreviewShow = () => {
    SetSubmissionPreview(true);
  };
  const handleSubmissionPreviewClose = () => {
    SetSubmissionPreview(false);
    setObjectURL("");
  };

  const SubmissionPreview = async (reumeid) => {
    try {
      const result = await axios.get(
        `${config?.apiUrl}/api/download_Submission_Resume/${reumeid}`,
        {
          responseType: "blob",
        }
      );
      setObjectURL(URL.createObjectURL(result.data));
      setLoading(false);
      // console.log(result.data);
      // return download(result.data);
    } catch (err) {
      setObjectURL("no-resume");
      setLoading(false);
      toast.error("Error while Preview file. Try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [moduleData, setModuleData] = useState({});
  console.log({ moduleData });
  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;
      const candidateModule = modules.find((item) => item.module === "Jobs");
      if (candidateModule) {
        const permissionsData = candidateModule.permissions;
        setModuleData(permissionsData);
      } else {
        console.log("Candidate module not found");
      }
    } catch (error) {
      console.error("Error while fetching module data:", error);
    }
  };
  useEffect(() => {
    fetchModuleData();
  }, [cookie?.role]);
  const [candidatename, setCandidatename] = useState({});
  useEffect(() => {
    if (submission?.candidateId) {
      const { firstName, lastName } = submission.candidateId;
      setCandidatename({
        firstName: firstName,
        lastName: lastName,
      });
    }
    const activityOn = `View ${submission?.candidateId?.firstName} ${submission?.candidateId?.lastName} submission `;
    const activityDo = "View Submission";
    const component = "submission";
    submission?.candidateId?.firstName &&
      userActivity(
        candidate_ownerName,
        userId,
        component,
        activityDo,
        activityOn,
        userRole
      );
  }, [submission?.candidateId?.firstName]);

  useEffect(() => {
    getSubmission(id);
    candidateid && getCandidateActivity(candidateid);
  }, [id, candidateid]);

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <Box>
      <ToastContainer />
      <Box
        className={isSidebarOpen ? "content_details" : "content_details_nav"}
      >
        <div
          style={{
            width: "auto",
            margin: "5rem 0rem 1.5rem 0rem",
          }}
        >
          <Flex
            position="relative"
            direction="column"
            align={{ sm: "center" }}
            maxW="auto"
            height="auto"
            mx="auto"
            bg={useColorModeValue("white", "gray.700")}
            shadow={{ sm: "base" }}
            rounded={{ sm: "lg" }}
            padding="7px 20px 7px 20px"
          >
            <Avatar
              mt="-10"
              borderWidth="6px"
              borderColor={useColorModeValue("white", "gray.700")}
              size="xl"
              name={displaysubmission?.candidateId?.firstName}
              className="bg_color"
              color="white"
            />
            <div className="box_details">
              <Box textAlign={{ sm: "center" }} pt="2">
                <Heading
                  size="lg"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  className="title_color"
                >
                  {displaysubmission?.candidateId?.firstName}{" "}
                  {displaysubmission?.candidateId?.lastName}
                </Heading>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  {displaysubmission?.candidateId?.designation}
                </Text>
              </Box>
              <hr className="horizontal_line" />
              <div>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  {displaysubmission?.candidateId?.phone}
                </Text>
                <Text color={useColorModeValue("blue.600")}>
                  {displaysubmission?.candidateId?.email}
                </Text>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  Created On :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {!displaysubmission?.created_at
                      ? "N/A"
                      : displaysubmission?.created_at}
                  </span>
                </Text>
              </div>
            </div>
          </Flex>
        </div>
        <Tabs
          style={{
            width: "100%",
          }}
          defaultIndex={0}
          variant="enclosed"
        >
          <TabList style={{ flexWrap: "wrap" }}>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Info
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Activities
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Card style={{ width: "100%", marginBottom: "2rem" }}>
                <Card.Header style={{ textAlign: "left", fontWeight: "bold" }}>
                  Submission Details
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ textAlign: "left" }}>
                    <Container style={{ minWidth: "100%" }}>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            First Name
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.firstName
                              ? "N/A"
                              : displaysubmission?.candidateId?.firstName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Last Name
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.lastName
                              ? "N/A"
                              : displaysubmission?.candidateId?.lastName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Email
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.email
                              ? "N/A"
                              : displaysubmission?.candidateId?.email}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Job Title
                          </Text>
                          <span>
                            {!displaysubmission?.jobId?.jobtitle
                              ? "N/A"
                              : displaysubmission?.jobId?.jobtitle}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Status
                          </Text>
                          <span style={{ color: "#000", fontWeight: "800" }}>
                            {!displaysubmission?.status
                              ? "N/A"
                              : displaysubmission?.status}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Current Candidate CTC Type
                          </Text>
                          <span>
                            {!displaysubmission?.currentCandidateCTCType
                              ? "N/A"
                              : displaysubmission?.currentCandidateCTCType}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Current Candidate CTC
                          </Text>
                          <span>
                            {!displaysubmission?.currentCandidateCTC
                              ? "N/A"
                              : displaysubmission?.currentCandidateCTC}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Expected Candidate CTC Type
                          </Text>
                          <span>
                            {!displaysubmission?.expectedCandidateCTCType
                              ? "N/A"
                              : displaysubmission?.expectedCandidateCTCType}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Expected Candidate CTC
                          </Text>
                          <span>
                            {!displaysubmission?.expectedCandidateCTC
                              ? "N/A"
                              : displaysubmission?.expectedCandidateCTC}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Notice Period
                          </Text>
                          <span>
                            {!displaysubmission?.noticePeriod
                              ? "N/A"
                              : displaysubmission?.noticePeriod}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Relevant Experiance
                          </Text>
                          <span>
                            {!displaysubmission?.relevantExperiance
                              ? "N/A"
                              : displaysubmission?.relevantExperiance}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Availability
                          </Text>
                          <span>
                            {!displaysubmission?.availability
                              ? "N/A"
                              : displaysubmission?.availability}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Engagement
                          </Text>
                          <span>
                            {!displaysubmission?.engagement
                              ? "N/A"
                              : displaysubmission?.engagement}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Location Preference
                          </Text>
                          <span>
                            {!displaysubmission?.locationPreference
                              ? "N/A"
                              : displaysubmission?.locationPreference}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              <Card style={{ width: "100%", marginBottom: "2rem" }}>
                <Card.Header style={{ textAlign: "left", fontWeight: "bold" }}>
                  Resume Details{" "}
                  <Tooltip
                    hasArrow
                    label="Resume Preview"
                    bg="gray.300"
                    color="black"
                  >
                    <DescriptionIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "17px",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        SubmissionPreview(submission._id, submission.filePath);
                        handleSubmissionPreviewShow(
                          setSubmission(submission),
                          setReumeId(submission._id)
                        );
                      }}
                    />
                  </Tooltip>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ textAlign: "left" }}>
                    <Container style={{ minWidth: "100%" }}>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            First Name
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.firstName
                              ? "N/A"
                              : displaysubmission?.candidateId?.firstName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Last Name
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.lastName
                              ? "N/A"
                              : displaysubmission?.candidateId?.lastName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Email
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.email
                              ? "N/A"
                              : displaysubmission?.candidateId?.email}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Phone
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.phone
                              ? "N/A"
                              : displaysubmission?.candidateId?.phone}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Designation
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.designation
                              ? "N/A"
                              : displaysubmission?.candidateId?.designation}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Skill
                          </Text>
                          <span>
                            {!displaysubmission?.candidateId?.skill
                              ? "N/A"
                              : displaysubmission?.candidateId?.skill}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              <Box className="btns_box">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <button
                    className="candi_btns"
                    onClick={() => {
                      SubmissionPreview(submission._id, submission.filePath);
                      handleSubmissionPreviewShow(
                        setSubmission(submission),
                        setReumeId(submission._id)
                      );
                    }}
                  >
                    Resume Preview
                  </button>
                  <Button
                    isDisabled={
                      (moduleData && moduleData.edit == "none") ||
                      (moduleData?.edit == "ownrecords" &&
                        submission?.candidate_ownerName !==
                          localStorage.getItem("candidate_ownerName"))
                    }
                    className="candi_btns"
                    onClick={() => {
                      handleEditShow(setSubmissionId(displaysubmission?._id));
                      setId(displaysubmission?._id);
                      setSubmission(displaysubmission);
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>

              {/* Modal for Edit Submission */}

              <Submissionform
                show={true}
                handleInputs={handleInputs}  
                handleActivitySubmit={handleActivitySubmit}
                applicationStatus={applicationStatus}
                candidateStatus={submission?.status}
                saveFile={saveFile}
                handleSubmitSubmissionClose={handleEditClose}
                submitSubmission={editSubmission}
                submission={submission}
                setSubmission={setSubmission}
                id={Id}
                setId={setId}
                handleEditShow={handleEditShow}
                editSubmission={editSubmission}
                handleEditClose={handleEditClose}
                candidatename={candidatename}
                component="submission"
                handleResumeMatching={handleResumeMatching}
                matchingSkills={matchingSkills}
                ParsedSkills={ParsedSkills}
                missingSkills={missingSkills}
                parseResumeConclusion={parseResumeConclusion}
                loadingResumeScreening={loadingResumeScreening}
                parsePercentage={parsePercentage}
                parseAge={parseAge}
                router={router}
                handleUpdateStatus={handleUpdateStatus}
                clearFilter={clearJobFilter}
                // getCandidatedata={getCandidatedata}
                getCandidatedata={handleFilterAllFieldStep}
              ></Submissionform>

              {/* Modal for Submission Preview */}
              <div className="model-box-view">
                <Modal
                  show={submissionPreview}
                  onHide={handleSubmissionPreviewClose}
                  backdrop="static"
                  keyboard={false}
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Resume Preview
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col lg={8} md={6} sm={6}>
                          {!objectURL ? (
                            <>
                              <div className="resume_ld">
                                <Loader />
                              </div>
                            </>
                          ) : objectURL === "no-resume" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={NotFound}
                                alt="NotFound"
                                style={{ width: "16rem" }}
                              />
                              <p>No Resume Found! Please try again....</p>
                            </div>
                          ) : (
                            <div
                              style={{
                                borderRight: "1px solid rgb(222, 226, 230)",
                                paddingRight: "18px",
                              }}
                            >
                              <iframe
                                src={objectURL}
                                width="100%"
                                height="800px"
                              />
                            </div>
                          )}
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                          <div className="resume_pr">
                            <div>
                              <Heading
                                size="md"
                                fontWeight="bold"
                                letterSpacing="tight"
                              >
                                {!displaysubmission?.candidateId?.firstName
                                  ? "N/A"
                                  : displaysubmission?.candidateId
                                      ?.firstName}{" "}
                                {!displaysubmission?.candidateId?.lastName
                                  ? "N/A"
                                  : displaysubmission?.candidateId?.lastName}
                              </Heading>
                              <Text
                                color={useColorModeValue(
                                  "gray.600",
                                  "gray.400"
                                )}
                              >
                                <CallOutlinedIcon
                                  style={{ fontSize: "medium" }}
                                />{" "}
                                {!displaysubmission?.candidateId?.phone
                                  ? "N/A"
                                  : displaysubmission?.candidateId?.phone}
                              </Text>
                              <Text
                                color={useColorModeValue(
                                  "gray.600",
                                  "gray.400"
                                )}
                              >
                                <EmailOutlinedIcon
                                  style={{ fontSize: "medium" }}
                                />{" "}
                                {!displaysubmission?.candidateId?.email
                                  ? "N/A"
                                  : displaysubmission?.candidateId?.email}
                              </Text>
                            </div>
                            <div>
                              <Tooltip
                                hasArrow
                                label="Download Resume"
                                bg="gray.300"
                                color="black"
                              >
                                <a href={objectURL} download>
                                  <button class="btn bg-white border rounded mb-2 text-break">
                                    {!displaysubmission?.fileName
                                      ? "N/A"
                                      : displaysubmission?.fileName}{" "}
                                    <VerticalAlignBottomIcon
                                      style={{ color: "grey" }}
                                    />
                                  </button>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="cancel"
                      onClick={handleSubmissionPreviewClose}
                    >
                      Cancel
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </TabPanel>
            <TabPanel>
              {activity?.map((value, index) => {
                return (
                  <>
                    <MDBContainer>
                      <ul className="timeline">
                        <li className="timeline-item">
                          <h5 className="activitytitle">{value.jobActivity}</h5>
                          {value && value?.submittedBy && (
                            <h5 className="activitytitle">
                              By : {value?.submittedBy}
                            </h5>
                          )}
                          <p className="activityinfo">
                            {
                              new Date(value.created_at)
                                .toLocaleString()
                                ?.split(",")[0]
                            }
                            <br />
                            {
                              new Date(value.created_at)
                                .toLocaleString()
                                ?.split(",")[1]
                            }
                          </p>
                        </li>
                      </ul>
                    </MDBContainer>
                  </>
                );
              })}
              <TabPanel></TabPanel>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default Submissionview;
