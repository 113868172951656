import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  CloseButton,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import NotFound from "assets/svg/not-found.svg";
import axios from "axios";
import { format } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FiSearch } from "react-icons/fi";
import config from "../../../config";
import { default as Loadar } from "../../../views/Dashboard/Loader";
import CommonPagination from "../../CommonPagination/CommonPagination";
import FilterUserActivity from "./FilterUserActivity";
import { useSelector } from "react-redux";
import CheckFilter from "../../CheckFilter/CheckFilter";
const ActivityReport = () => {
  const {userNames} = useSelector((selector)=>selector.usersSlice)
  const [searchMainDate, setSearchMainDate] = useState([]);
  const [userActivities, setUserActivities] = useState([]);
  const [userNameOptions, setUserNameOptions] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages,setTotalPages]=useState(1)
  const [searchUserName, setSearchUserName] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [searchModule, setSearchModule] = useState([]);
  const [searchActivityon, setSearchActivityon] = useState("");
  const [searchActivity, setSearchActivity] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [activeField, setActiveField] = useState({ name: "", value: "" });
  const [IsFilterActiveDate, setIsFilterActiveDate] = useState(false);
  const [IsFilterActiveActivity, setIsFilterActiveActivity] = useState(false);
  const [IsFilterActiveUserName, setIsFilterActiveUserName] = useState(false);
  const [IsFilterActiveModule, setIsFilterActiveModule] = useState(false);
  const [IsFilterActiveActivityon, setIsFilterActiveActivityon] =
    useState(false);
   
  const [popoverVisible, setPopoverVisible] = useState({});
  const handlePopOverClose = (key, visibility) => {
    const newPopoverVisible = key ? { [key]: visibility } : {};
    setPopoverVisible(newPopoverVisible);
  };

  const popOverConfig = useCallback(
    (key) => {
      return {
        trigger: "click",
        placement: "auto",
        rootClose: key == "username" || key == "module" ? false : true,
        show: !!popoverVisible?.[key],
        onToggle: (isShow) => handlePopOverClose(key, isShow),
      };
    },
    [popoverVisible]
  );

  const userData = userNameOptions.map((user) => ({
    label: `${user}`,
    value: `${user}`,
  }));

  const moduleData = moduleOptions.map((module) => ({
    label: `${module}`,
    value: `${module}`,
  }));

  const fetchModuleOptions = async () => {
    try {
      const options = [
        "candidate",
        "jobpost",
        "account",
        "submission",
        "qacheckcandidate",
        "interviewschedule",
        "interviewattained",
        "interviewcomplete",
        "selected",
        "joined",
        "note",
        "reject",
        "backout",
        "Login",
        "Log out"
      ];
      setModuleOptions(options);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [loading, setLoading] = useState(false);

  const getAllActivity = async (page, rowsPerPage) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/getActivities?page=${page + 1}&limit=${rowsPerPage}`
      );
      setUserActivities(response.data.data);
      setTotalPages(Math.ceil(response?.data?.totalCount / rowsPerPage))
      setTotalCount(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const clearFilter = () => {
    setSearchUserName([]);
    setSearchUsers([]);
    setSearchModule([]);
    setSearchActivityon("");
    setSearchActivity("");
    setSearchDate("");
    setIsFilterActiveActivity(false);
    setIsFilterActiveDate(false);
    setIsFilterActiveActivityon(false);
    setIsFilterActiveUserName(false);
    setIsFilterActiveModule(false);
    setActiveField({ name: "", value: "" });

    setSelectedUserName([]);
    setSelectedModules([]);
    setSearchMainDate([]);
    setRowsPerPage(10);
    setPage(0);
    getAllActivity(0, rowsPerPage);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if (
      searchUserName.length !== 0 ||
      searchModule.length !== 0 ||
      searchActivityon !== "" ||
      searchActivity !== "" ||
      searchDate !== "" ||
      selectedUserName.length !== 0 ||
      selectedModules.length !== 0 ||
      searchUsers.length !== 0 ||
      searchMainDate?.length !== 0
    ) {
      filterData(newPage, rowsPerPage, activeField.name, activeField.value);
    } else {
      getAllActivity(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const limitValue = +event.target.value;
    setRowsPerPage(limitValue);
    setPage(0);
    if (
      searchUserName.length !== 0 ||
      searchModule.length !== 0 ||
      searchActivityon !== "" ||
      searchActivity !== "" ||
      searchDate !== "" ||
      selectedUserName.length !== 0 ||
      selectedModules.length !== 0 ||
      searchUsers.length !== 0 ||
      searchMainDate?.length !== 0
    ) {
      filterData(0, limitValue, activeField.name, activeField.value);
    } else {
      getAllActivity(0, limitValue);
    }
  };

  const filterData = async (
    page = 0,
    rowsPerPage = 10,
    fieldName,
    value = ""
  ) => {
    setLoading(true);
    const formattedDates =
      searchMainDate &&
      searchMainDate.map((date) => format(date, "yyyy-MM-dd"));
    try {
      const response = await axios.get(`${config.apiUrl}/filterActivities`, {
        params: {
          module: fieldName == "module" ? value : selectedModules,
          userName: fieldName == "username" ? value : selectedUserName,
          searchMainDate: fieldName == "searchDate" ? value : formattedDates,
          activityOn: fieldName == "activityon" ? value : searchActivityon,
          date: fieldName == "selectDate" ? value : searchDate,
          searchModule: fieldName == "searchModule" ? value : searchModule,
          users: fieldName == "users" ? value : searchUsers,
          activity: fieldName == "activity" ? value : searchActivity,
          page: page + 1,
          limit: Number(rowsPerPage),
        },
      });
      setUserActivities(response.data.data);
      setTotalCount(response.data.totalCount);
      setLoading(false);
      handlePopOverClose(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      handlePopOverClose(false);
    }
  };
  const fetchUserNames = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/api/usersname`);
      const options = response.data.map((name) => name.userName);
      setUserNameOptions(options);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAllActivity(page, rowsPerPage);
    fetchUserNames();
    fetchModuleOptions();
  }, []);

  return (
    <>
      <div style={{ marginTop: "7rem" }}>
        <div className="table_box" style={{ paddingBottom: "2rem" }}>
          <FilterUserActivity
          // isShow={true}
            selectedUserName={selectedUserName}
            handleApplyFilters={filterData}
            handleResetFilters={clearFilter}
            setSelectedUserName={setSelectedUserName}
            userNameOptions={userNames}
            moduleOptions={moduleOptions}
            selectedModules={selectedModules}
            setSelectedModules={setSelectedModules}
            setSearchMainDate={setSearchMainDate}
            searchMainDate={searchMainDate}
            page={page}
            rowsPerPage={rowsPerPage}
            setActiveField={setActiveField}
            activeField={activeField}
          />
        </div>

        <Box
          overflowX="auto"
          mt={'120px'}
          p={2}
          shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
        >
          <TableContainer style={{ maxHeight: 680 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    User Name
                    <OverlayTrigger
                      {...popOverConfig("username")}
                      overlay={
                        <Popover
                          id="popover-basic"
                          style={{ maxWidth: "100%" }}
                        >
                          <Popover.Body>
                            <CheckFilter
                              filterData={filterData}
                              setActiveField={setActiveField}
                              handlePopOverClose={handlePopOverClose}
                              field={"users"}
                              placeholder={"Select Industry"}
                              value={searchUsers}
                              setValue={setSearchUsers}
                              data={userNames?.map((res) => {
                                return {
                                  value: res._id,
                                  label: `${res.firstName} ${res.lastName}`,
                                };
                              })}
                              isFilter={IsFilterActiveUserName}
                              setIsFilter={setIsFilterActiveUserName}
                              page={page+1}
                              rowsPerPage={rowsPerPage}
                            />
                            {/* <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              <CommonCheckPicker
                                data={userNames?.map((res) => {
                                  return {
                                    value: res._id,
                                    label: `${res.firstName} ${res.lastName}`,
                                  };
                                })}
                                value={searchUsers}
                                placeholder="Select Users"
                                onChange={(selectedOption) => {
                                  setSearchUsers(selectedOption);
                                  setActiveField({
                                    name: "users",
                                    value: selectedOption,
                                  });
                                }}
                                onClean={() => {
                                  filterData(page, rowsPerPage, "users", "");
                                  setIsFilterActiveUserName(false);
                                  setSearchUsers([]);
                                  setActiveField({
                                    name: "users",
                                    value: "",
                                  });
                                  handlePopOverClose(false);
                                }}
                                width={224}
                              />
                              <IconButton
                                color={"#fff"}
                                background={"var(--main-bg-color)"}
                                aria-label="Search date range"
                                _hover={{
                                  background: "var(--main-bg-color)",
                                }}
                                size={"sm"}
                                icon={<FiSearch />}
                                isDisabled={searchUsers?.length == 0}
                                onClick={() => {
                                  setIsFilterActiveUserName(true);
                                  filterData(
                                    page,
                                    rowsPerPage,
                                    "users",
                                    searchUsers
                                  );
                                }}
                              />
                            </Box> */}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {IsFilterActiveUserName ? (
                        <FilterAltIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FilterListIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>
                  <TableCell>
                    Select Date
                    <OverlayTrigger
                      {...popOverConfig("date")}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                              style={{ width: "auto" }}
                            >
                              <InputGroup>
                                <InputLeftElement
                                  children={
                                    <SearchIcon sx={{ cursor: "pointer" }} />
                                  }
                                  onClick={() => {
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "selectDate",
                                      searchDate
                                    );
                                    setIsFilterActiveDate(true);
                                    setActiveField({
                                      name: "selectDate",
                                      value: searchDate,
                                    });
                                  }}
                                />
                                <Input
                                  type="date"
                                  id="selecteddate"
                                  placeholder="Published Date..."
                                  value={searchDate}
                                  onChange={(e) => {
                                    setSearchDate(e.target.value);
                                    setActiveField({
                                      name: "selectDate",
                                      value: e.target.value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      filterData(
                                        page,
                                        rowsPerPage,
                                        "selectDate",
                                        searchDate
                                      );
                                      setIsFilterActiveDate(true);
                                      setActiveField({
                                        name: "selectDate",
                                        value: searchDate,
                                      });
                                    }
                                  }}
                                  style={{
                                    height: "37px",
                                    padding: "9px",
                                    paddingLeft: "2.6rem",
                                    color: "#7B9AAA",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    lineHeight: "19px",
                                    fontSize: "15px",
                                  }}
                                />
                              </InputGroup>
                              {IsFilterActiveDate && (
                                <CloseButton
                                  sx={{
                                    backgroundColor: "var(--main-bg-color)",
                                    color: "#fff",
                                  }}
                                  _hover={{
                                    backgroundColor: "var(--main-bg-color)",
                                  }}
                                  onClick={() => {
                                    setIsFilterActiveDate(false);
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "selectDate",
                                      ""
                                    );
                                    setActiveField({
                                      name: "selectDate",
                                      value: "",
                                    });
                                  }}
                                />
                              )}
                            </Box>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {IsFilterActiveDate ? (
                        <FilterAltIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FilterListIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>
                  <TableCell>
                    Module
                    <OverlayTrigger
                      {...popOverConfig("module")}
                      overlay={
                        <Popover
                          id="popover-basic"
                          style={{ maxWidth: "100%" }}
                        >
                          <Popover.Body>
                            <CheckFilter
                              filterData={filterData}
                              setActiveField={setActiveField}
                              handlePopOverClose={handlePopOverClose}
                              field={"searchModule"}
                              placeholder={"Select Modules"}
                              value={searchModule}
                              setValue={setSearchModule}
                              data={moduleData}
                              isFilter={IsFilterActiveModule}
                              setIsFilter={setIsFilterActiveModule}
                              page={page+1}
                              rowsPerPage={rowsPerPage}
                            />
                            {/* <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              <CommonCheckPicker
                                data={moduleData}
                                value={searchModule}
                                placeholder="Select Modules"
                                onChange={(selectedOption) => {
                                  setSearchModule(selectedOption);
                                  setActiveField({
                                    name: "searchModule",
                                    value: selectedOption,
                                  });
                                }}
                                onClean={() => {
                                  filterData(
                                    page,
                                    rowsPerPage,
                                    "searchModule",
                                    ""
                                  );
                                  setSearchModule([]);
                                  setActiveField({
                                    name: "searchModule",
                                    value: "",
                                  });
                                  setIsFilterActiveModule(false);
                                  handlePopOverClose(false);
                                }}
                                width={224}
                              />
                              <IconButton
                                color={"#fff"}
                                background={"var(--main-bg-color)"}
                                aria-label="Search date range"
                                _hover={{
                                  background: "var(--main-bg-color)",
                                }}
                                size={"sm"}
                                icon={<FiSearch />}
                                isDisabled={searchModule?.length == 0}
                                onClick={() => {
                                  setIsFilterActiveModule(true);
                                  filterData(
                                    page,
                                    rowsPerPage,
                                    "searchModule",
                                    searchModule
                                  );
                                }}
                              />
                            </Box> */}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {IsFilterActiveModule ? (
                        <FilterAltIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FilterListIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>
                  <TableCell>
                    Activity on
                    <OverlayTrigger
                      {...popOverConfig("activityon")}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <Box
                              sx={{
                                width: "auto",
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              <InputGroup>
                                <InputLeftElement
                                  children={
                                    <SearchIcon sx={{ cursor: "pointer" }} />
                                  }
                                  onClick={() => {
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "activityon",
                                      searchActivityon
                                    );
                                    setIsFilterActiveActivityon(true);
                                    setActiveField({
                                      name: "activityon",
                                      value: searchActivityon,
                                    });
                                  }}
                                />
                                <Input
                                  type="text"
                                  value={searchActivityon}
                                  placeholder="Search User Name..."
                                  onChange={(e) => {
                                    setSearchActivityon(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      filterData(
                                        page,
                                        rowsPerPage,
                                        "activityon",
                                        searchActivityon
                                      );
                                      setIsFilterActiveActivityon(true);
                                    }
                                  }}
                                />
                              </InputGroup>
                              {IsFilterActiveActivityon && (
                                <CloseButton
                                  sx={{
                                    backgroundColor: "var(--main-bg-color)",
                                    color: "#fff",
                                  }}
                                  _hover={{
                                    backgroundColor: "var(--main-bg-color)",
                                  }}
                                  onClick={() => {
                                    setIsFilterActiveActivityon(false);
                                    setSearchActivityon("");
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "activityon",
                                      ""
                                    );
                                    setActiveField({
                                      name: "activityon",
                                      value: "",
                                    });
                                  }}
                                />
                              )}
                            </Box>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {IsFilterActiveActivityon ? (
                        <FilterAltIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FilterListIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>{" "}
                  <TableCell>
                    Activity
                    <OverlayTrigger
                      {...popOverConfig("activity")}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <Box
                              sx={{
                                width: "auto",
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              <InputGroup>
                                <InputLeftElement
                                  children={
                                    <SearchIcon sx={{ cursor: "pointer" }} />
                                  }
                                  onClick={() => {
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "activity",
                                      searchActivity
                                    );
                                    setIsFilterActiveActivity(true);
                                    setActiveField({
                                      name: "activityon",
                                      value: searchActivity,
                                    });
                                  }}
                                />
                                <Input
                                  type="text"
                                  value={searchActivity}
                                  placeholder="Search User Name..."
                                  onChange={(e) => {
                                    setSearchActivity(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      filterData(
                                        page,
                                        rowsPerPage,
                                        "activity",
                                        searchActivity
                                      );
                                      setIsFilterActiveActivity(true);
                                    }
                                  }}
                                />
                              </InputGroup>
                              {IsFilterActiveActivity && (
                                <CloseButton
                                  sx={{
                                    backgroundColor: "var(--main-bg-color)",
                                    color: "#fff",
                                  }}
                                  _hover={{
                                    backgroundColor: "var(--main-bg-color)",
                                  }}
                                  onClick={() => {
                                    setIsFilterActiveActivity(false);
                                    setSearchActivity("");
                                    filterData(
                                      page,
                                      rowsPerPage,
                                      "activity",
                                      ""
                                    );
                                    setActiveField({
                                      name: "activity",
                                      value: "",
                                    });
                                  }}
                                />
                              )}
                            </Box>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {IsFilterActiveActivity ? (
                        <FilterAltIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FilterListIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box textAlign="center">
                        <Loadar />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {userActivities && userActivities?.length == 0 ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img src={NotFound} alt="NotFound" />
                            <Text>No Records Found! Please try again....</Text>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      userActivities.map((activity, index) => {
                        const formattedDate = `${moment(
                          activity.created_at
                        ).format(
                          "DD/MM/YYYY"
                        )} ${moment(activity.created_at).format("h:mm:ss A")}`;

                        const truncatedText =
                          activity?.activityOn &&
                          activity?.activityOn?.length > 40
                            ? `${activity?.activityOn?.slice(0, 40)}...`
                            : activity?.activityOn;

                        return (
                          <TableRow key={index}>
                            <TableCell>{`${activity.userId?.firstName} ${activity.userId?.lastName}`}</TableCell>
                            <TableCell>{formattedDate}</TableCell>
                            <TableCell>{activity.component}</TableCell>
                            <TableCell>
                              <Tooltip
                                label={
                                  activity?.activityOn?.length > 40 &&
                                  activity?.activityOn
                                }
                              >
                                {!activity?.activityOn ? "N/A" : truncatedText}
                              </Tooltip>
                            </TableCell>
                            <TableCell>{activity.activity}</TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>


          <CommonPagination
              rowsPerPage={rowsPerPage}
              page={page}
              totalPages={Math.ceil(totalCount / rowsPerPage)}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              count={totalCount}
            />
        </Box>
      </div>
    </>
  );
};

export default ActivityReport;
