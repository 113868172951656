import React from "react";
import { Slider, Typography, makeStyles, Paper, Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { CloseButton, Flex, IconButton, Text } from "@chakra-ui/react";
import { CiSearch } from "react-icons/ci";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    margin: "0 auto",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginBottom: theme.spacing(2),
    color: "#023f61",
    fontWeight: 600,
    textAlign: "center",
  },
  slider: {
    color: "#023f61",
    marginTop:"-4px",
    width: "100%",
    
    "& .MuiSlider-track": {
      height: 6,
    },

    "& .MuiSlider-rail": {
      height: 6,
    },

    "& .MuiSlider-thumb": {
      width: 18,
      height: 18,
    },
  },
}));

function CommonExperienceRange({
  filterData,
  inputState,
  fieldName ,
  setInputState ,
  setActiveField ,
  activeFieldName ,
  isFilterActiveState ,
  setIsFilterActiveState,
  inputPlaceholder ,
  maxValue,
}) {
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    setInputValue((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  const [inputValue, setInputValue] = React.useState(
    inputState || {
      0: 1,
      1: 5,
      [fieldName]: [1, 5],
    }
  );
  const toSetFilter = () => {
    if (fieldName == "noticePeriod") {
      setInputState(inputValue);
      setActiveField({
        name: activeFieldName,
        value: `${inputValue?.noticePeriod[0] || 0}-${inputValue?.noticePeriod[1]}`,
      });
      filterData(
        `${inputValue?.noticePeriod[0]}-${inputValue?.noticePeriod[1]}`,
        fieldName
      );
    } else {
      setInputState(inputValue);
      setActiveField({
        name: activeFieldName,
        value: inputValue,
      });
      filterData(inputValue, fieldName);
    }
  };

  return (
    <Box elevation={3}>
      <Text fontSize="14px" fontWeight={500}>{inputPlaceholder}</Text>
      <Flex justifyContent={'space-between'} w={'220px'} alignItems={'center'} >
      <IconButton
          marginLeft={"-10px"}
          disabled={!inputValue}
          _hover={{ background: "transparent" }}
          background={"transparent"}
          _focus={{ outline: "none" }}
          onClick={toSetFilter}
        >
          <CiSearch style={{ fontSize: "1.4rem" }} />
        </IconButton>
        <Slider
          className={classes.slider}
          color="#023f61"
          getAriaLabel={() => "Minimum distance"}
          value={inputValue[fieldName]   || [0, 3]}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={maxValue || 25}
          disableSwap
        />
        {isFilterActiveState && (
          <Box >
            <CloseButton
              size="sm"
              marginLeft={"10px"}
              onClick={() => {
                setIsFilterActiveState(false);
                filterData({[fieldName]:[null,null]}, fieldName);
                setInputState(null); 
                setInputValue({
                  0: 1,
                  1: 5,
                  [fieldName]: [1, 5],
              });
                setActiveField({
                  name: fieldName,
                  value: false,
                });
                
              }}
              _focus={{ boxShadow: "none" }}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
}

export default CommonExperienceRange;
