import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";
import AccountPreviewModel from "components/Commoncomponent/AccountPreviewModel";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import config from "../../config";
import "./org.css";

const edituser = Yup.object().shape({
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  email: Yup.string().email().required("Enter Email Address"),
  phone: Yup.string().required("Please Enter Mobile Number"),
  password: Yup.string(),
  PancardNumber: Yup.string(),
  AadharcardNumber: Yup.string(),
  dateofBirth: Yup.string(),
  address: Yup.string().required("Please Enter Address"),
  bankAccounNumber: Yup.string(),
  ifscCode: Yup.string(),
  bankName: Yup.string(),
  role: Yup.string().required("Please Select Role"),
  offerLetter: Yup.mixed(),
  workOrder: Yup.mixed(),
  otherDocuments: Yup.mixed(),
});

function Organizationview() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleGoBack = () => {
    history?.goBack();
  };
  const [userPreview, SetUserPreview] = useState(false);
  const [offerLetter, setOfferLetter] = useState(null);
  const [workOrder, setWorkOrder] = useState(null);
  const [otherDocuments, setOtherDocuments] = useState(null);
  const [user, setUser] = useState([]);
  const [ViewEdit, SetEditShow] = useState(false);
  const [userSubmission, setUserSubmission] = useState([]);
  const [pipelinedUsers, setPipelinedUsers] = useState([]);
  const [interviewedUsers, setInterviewedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [acceptedUsers, setAcceptedUsers] = useState([]);
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const [interviewfeedbackUsers, setInterviewfeedbackUsers] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [userId, setUserId] = useState("");
  const [editUser, setEdituser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dateofbirth: "",
    address: "",
    aadharcardNumber: "",
    pancardNumber: "",
    bankAccounNumber: "",
    ifscCode: "",
    bankName: "",
    role: "",
  });

  const handleEditShow = () => {
    SetEditShow(true);
  };
  const handleEditClose = () => {
    SetEditShow(false);
    setSelectedType("");
    setObjectURL(null);
    SetUserPreview(false);
    setOfferLetter(null);
    setOtherDocuments(null);
    setWorkOrder(null);
    setEdituser({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateofbirth: "",
      address: "",
      aadharcardNumber: "",
      pancardNumber: "",
      bankAccounNumber: "",
      ifscCode: "",
      bankName: "",
      role: "",
    });
  };

  const saveFile = (e, fileType) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      switch (fileType) {
        case "offerLetter":
          setOfferLetter(selectedFile);
          editFormik.setFieldValue("offerLetter", selectedFile);
          break;
        case "workOrder":
          setWorkOrder(selectedFile);
          editFormik.setFieldValue("workOrder", selectedFile);
          break;
        case "otherDocuments":
          setOtherDocuments(selectedFile);
          editFormik.setFieldValue("otherDocuments", selectedFile);
          break;
        default:
          break;
      }
    }
  };
  const [selectedType, setSelectedType] = useState(null);
  const [isTypeSelectionOpen, setIsTypeSelectionOpen] = useState(false);

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setIsTypeSelectionOpen(false);
  };

  let { id } = useParams();


  const getUser = (id) => {
    axios
      .get(`${config?.apiUrl}/organizationview/${id}`)
      .then((response) => {
        const userdata = response.data.data;
        setUser(userdata);
        setOfferLetter(userdata?.userDetail?.offerLetter);
        setOtherDocuments(userdata?.userDetail?.otherDocuments);
        setWorkOrder(userdata?.userDetail?.workOrder);
        setEdituser(userdata);
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const editFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phone: "",
      dateofbirth: "",
      address: "",
      aadharcardNumber: "",
      pancardNumber: "",
      bankAccounNumber: "",
      ifscCode: "",
      bankName: "",
      role: "",
      workOrder: null,
      offerLetter: null,
      otherDocuments: null,
    },
    validationSchema: edituser,
    onSubmit: async (item) => {
      setLoading(true);
      const formData = new FormData();
      const url = `${config?.apiUrl}/api/update_user/${id}`;

      for (let key in item) {
        formData.append(key, item[key]);
      }
      try {
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        handleEditClose();
        getUser(id);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });
  const getUserData = (item) => {
    editFormik.setValues({
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phone: item.phone,
      password: item.password,
      dateofbirth: item.dateofbirth,
      address: item.address,
      aadharcardNumber: item.aadharcardNumber,
      pancardNumber: item.pancardNumber,
      bankAccounNumber: item.bankAccounNumber,
      ifscCode: item.ifscCode,
      bankName: item.bankName,
      role: item.role,
      workOrder: item?.userDetail?.workOrder,
      offerLetter: item?.userDetail?.offerLetter,
      otherDocuments: item?.userDetail?.otherDocuments,
    });
    setWorkOrder(item?.userDetail?.workOrder);
    setOfferLetter(item?.userDetail?.offerLetter);
    setOtherDocuments(item?.userDetail?.otherDocuments);
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      dateofbirth,
      address,
      aadharcardNumber,
      pancardNumber,
      bankAccounNumber,
      ifscCode,
      bankName,
      role,
    } = editUser;

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("dateofbirth", dateofbirth);
    formData.append("address", address);
    formData.append("aadharcardNumber", aadharcardNumber);
    formData.append("pancardNumber", pancardNumber);
    formData.append("bankAccounNumber", bankAccounNumber);
    formData.append("ifscCode", ifscCode);
    formData.append("bankName", bankName);
    formData.append("role", role);
    formData.append("offerLetter", offerLetter);
    formData.append("otherDocuments", otherDocuments);
    formData.append("workOrder", workOrder);

    const url = `${config?.apiUrl}/api/update_user/${userId}`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(response.data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleEditClose();
      getUser(id);
    } catch (err) {
      toast.error(err?.response?.data?.msg || "Please check details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getUserSubmission = (id) => {
    axios
      .get(`${config?.apiUrl}/api/get_user_submission/${id}`)
      .then((response) => {
        const userSubmissiondata = response.data.data;
        setUserSubmission(userSubmissiondata);
        // setLoading(false);

        const pipelnefiltered = userSubmissiondata.filter((val) => {
          return val.status === "Submitted";
        });
        setPipelinedUsers(pipelnefiltered);

        const Interviewfiltered = userSubmissiondata.filter((val) => {
          return val.status === "Interview Scheduled";
        });
        setInterviewedUsers(Interviewfiltered);

        const Selectedfiltered = userSubmissiondata.filter((val) => {
          return val.status === "Selected";
        });
        setSelectedUsers(Selectedfiltered);

        const Acceptedfiltered = userSubmissiondata.filter((val) => {
          return val.status === "Joined";
        });
        setAcceptedUsers(Acceptedfiltered);

        const Onboardedfiltered = userSubmissiondata.filter((val) => {
          return val.status === "Onboarded";
        });
        setOnboardedUsers(Onboardedfiltered);

        const Interviewfeedbackfiltered = userSubmissiondata.filter((val) => {
          return val.status === "Interview feedback Awaiting";
        });
        setInterviewfeedbackUsers(Interviewfeedbackfiltered);
      })
      .catch((err) => {
        toast.error(
          "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const [objectURL, setObjectURL] = useState(null);
  const UserPreview = async (id, selection) => {
    try {
      const result = await axios.get(
        `${config?.apiUrl}/api/download_User_Doc/${id}?selection=${selection}`,
        {
          responseType: "blob",
        }
      );

      setObjectURL(URL.createObjectURL(result.data));
    } catch (error) {
      setObjectURL("no-resume");
      toast.error("Error while Preview file. Try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleAccountPreviewShow = () => {
    SetUserPreview(true);
  };

  useEffect(() => {
    getUser(id);
    getUserSubmission(id);
  }, []);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box>
      <ToastContainer />
      <Box
        className={isSidebarOpen ? "content_details" : "content_details_nav"}
      >
        <div
          style={{
            width: "auto",
            margin: "5rem 0rem 1.5rem 0rem",
          }}
        >
          <Flex
            position="relative"
            direction="column"
            align={{ sm: "center" }}
            maxW="auto"
            height="auto"
            mx="auto"
            bg={useColorModeValue("white", "gray.700")}
            shadow={{ sm: "base" }}
            rounded={{ sm: "lg" }}
            padding="7px 20px 7px 20px"
          >
            <Avatar
              mt="-10"
              borderWidth="6px"
              borderColor={useColorModeValue("white", "gray.700")}
              size="xl"
              name={user.firstName}
              className="bg_color"
              color="white"
            />
            <div className="box_details">
              <Box textAlign={{ sm: "center" }} pt="2">
                <Heading size="lg" fontWeight="extrabold" letterSpacing="tight">
                  {!user.firstName ? "N/A" : user.firstName}{" "}
                  {!user.lastName ? "N/A" : user.lastName}
                </Heading>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  {!user.email ? "N/A" : user.email}
                </Text>
              </Box>
              <hr className="horizontal_line" />
              <div>
                <Text color={useColorModeValue("var(--main-bg-color)")}>
                  {!user.role ? "N/A" : user.role}
                </Text>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  {!user.phone ? "N/A" : user.phone}
                </Text>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  Created On :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {!user?.created_at
                      ? "N/A"
                        : new Date(user?.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                  </span>
                </Text>
              </div>
            </div>
          </Flex>
        </div>
        <Tabs
          style={{
            width: "100%",
          }}
          defaultIndex={1}
          variant="enclosed"
        >
          <TabList style={{ flexWrap: "wrap" }}>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Overview
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Info
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                <Row>
                  <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                    <div
                      style={{
                        backgroundColor: "#e3ebf8",
                        padding: "30px",
                        width: "auto",
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      }}
                    >
                      <div class="container">
                        <div
                          className="row"
                          style={{ gap: "23px", height: "auto" }}
                        >
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Pipelined
                            </Text>
                            <Link
                              to={`/admin/total_Pipelined?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {pipelinedUsers?.length}
                              </Heading>
                            </Link>
                          </div>
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Submitted
                            </Text>
                            <Link
                              to={`/admin/total_Submitted?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {userSubmission?.length}
                              </Heading>
                            </Link>
                          </div>
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Interviewed
                            </Text>
                            <Link
                              to={`/admin/total_Interviewed?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {interviewedUsers?.length}
                              </Heading>
                            </Link>
                          </div>
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Selected
                            </Text>
                            <Link
                              to={`/admin/total_SelectedCandidates?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {selectedUsers?.length}
                              </Heading>
                            </Link>
                          </div>
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Accepted
                            </Text>
                            <Link
                              to={`/admin/total_JoinedCandidates?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {acceptedUsers?.length}
                              </Heading>
                            </Link>
                          </div>
                          <div
                            className="col-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "end",
                              paddingBottom: "16px",
                              backgroundColor: "white",
                              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              borderRadius: "8px",
                            }}
                          >
                            <Text fontSize="lg" color="muted">
                              Onboarded
                            </Text>
                            <Link
                              to={`/admin/total_OnboardedCandidates?id=${id}&type=usersub`}
                            >
                              <Heading
                                style={{
                                  display: "flex",
                                  fontSize: "23px",
                                  padding: "40px",
                                  cursor: "pointer",
                                }}
                              >
                                {onboardedUsers?.length}
                              </Heading>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                    <div
                      style={{
                        backgroundColor: "#e3ebf8",
                        padding: "30px",
                        width: "auto",
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      }}
                    >
                      <Heading
                        style={{
                          fontSize: "20px",
                          marginBottom: "15px",
                        }}
                        className="title_color"
                      >
                        To-Do
                      </Heading>
                      <UnorderedList>
                        <ListItem>0 Tasks pending</ListItem>
                        <ListItem>
                          {interviewedUsers?.length} Interviews upcoming
                        </ListItem>
                        <ListItem>
                          {interviewfeedbackUsers?.length} Interviews awaiting
                          feedback
                        </ListItem>
                        <ListItem>
                          {pipelinedUsers?.length} Submissions pending
                        </ListItem>
                      </UnorderedList>
                    </div>
                  </Col>
                </Row>
              </Box>
            </TabPanel>
            <TabPanel>
              <Card style={{ width: "100%" }}>
                <Card.Header style={{ textAlign: "left", fontWeight: "bold" }}>
                  Personal Details
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ textAlign: "left" }}>
                    <Container style={{ minWidth: "100%" }}>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            First Name
                          </Text>
                          <span>
                            {!user.firstName ? "N/A" : user.firstName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Last Name
                          </Text>
                          <span>{!user.lastName ? "N/A" : user.lastName}</span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Email
                          </Text>
                          <span>{!user.email ? "N/A" : user.email}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Phone
                          </Text>
                          <span>{!user.phone ? "N/A" : user.phone}</span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Date Of Birth
                          </Text>
                          <span>
                            {!user.dateofbirth ? "N/A" : user.dateofbirth}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Address
                          </Text>
                          <span>{!user.address ? "N/A" : user.address}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Role
                          </Text>
                          <span>{!user.role ? "N/A" : user.role}</span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Aadhar Card Number
                          </Text>
                          <span>
                            {!user.aadharcardNumber
                              ? "N/A"
                              : user.aadharcardNumber}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Pan Card Number
                          </Text>
                          <span>
                            {!user.pancardNumber ? "N/A" : user.pancardNumber}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Bank Account Number
                          </Text>
                          <span>
                            {!user.bankAccounNumber
                              ? "N/A"
                              : user.bankAccounNumber}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            IFSC
                          </Text>
                          <span>{!user.ifscCode ? "N/A" : user.ifscCode}</span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Bank Name
                          </Text>
                          <span>{!user.bankName ? "N/A" : user.bankName}</span>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              <Box className="btns_box">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  {!selectedType && (
                    <button
                      className="candi_btns"
                      id="resumeSelectButton"
                      onClick={() => {
                        setIsTypeSelectionOpen(!isTypeSelectionOpen);
                      }}
                    >
                      Select User Details
                    </button>
                  )}

                  {/* Resume Type Selection Dropdown */}
                  {isTypeSelectionOpen && (
                    <div>
                      <select
                        onChange={(e) => handleTypeSelection(e.target.value)}
                        style={{
                          padding: "10px",
                          fontSize: "14px",
                          width: "200px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          cursor: "pointer",
                          background: "#01926f",
                          color: "#fff",
                          fontWeight: "900",
                        }}
                      >
                        <option
                          value=""
                          style={{
                            fontStyle: "italic",
                            color: "#fff",
                            background: "gray",
                            fontWeight: "900",
                          }}
                        >
                          Select User Document
                        </option>
                        {user?.userDetail &&
                          Object.keys(user.userDetail).map((key) => (
                            <option
                              key={key}
                              value={key}
                              style={{
                                textTransform: "uppercase",
                                padding: "8px",
                                color: "#fff",
                                background: "gray",
                                fontWeight: "900",
                              }}
                            >
                              {key}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  {selectedType && (
                    <button
                      className="candi_btns"
                      id="resumePerviewButton"
                      onClick={() => {
                        UserPreview(user?._id, selectedType);
                        handleAccountPreviewShow();
                      }}
                    >
                      Preview {selectedType}
                    </button>
                  )}
                  <button
                    className="candi_btns"
                    onClick={() => {
                      handleEditShow(setUserId(user?._id));
                      getUser(user?._id);
                      getUserData(user);
                    }}
                  >
                    Edit
                  </button>
                </Box>
              </Box>
            </TabPanel>
            {/* Modal for Edit User */}

            <div className="model-box-view">
              <Modal
                show={ViewEdit}
                onHide={handleEditClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="usertitle">Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={editFormik.handleSubmit}>
                    {profileImage == "" ? (
                      <>
                        <div className="userprofile">
                          <Avatar
                            me={{ md: "22px" }}
                            w="140px"
                            h="140px"
                            borderRadius="15px"
                            cursor="pointer"
                          />
                          <Box
                            sx={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: "#a4acc4",
                              borderRadius: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              marginTop: "-1.7rem",
                              zIndex: "99",
                              marginLeft: "7rem",
                              cursor: "pointer",
                            }}
                          >
                            <DriveFileRenameOutlineIcon
                              sx={{
                                color: "white",
                                marginLeft: "8px",
                                cursor: "pointer",
                              }}
                            />
                            <input
                              type="file"
                              style={{
                                marginLeft: "-32px",
                                opacity: "0",
                              }}
                              // onChange={UpdateImage}
                            />
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="userprofile">
                          <img src={profileImage} alt="" />
                        </div>
                      </>
                    )}

                    <FormControl>
                      <Container className="userdetail">
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              First Name
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Your First name"
                              size="lg"
                              name="firstName"
                              value={editFormik.values.firstName}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  editFormik.touched.firstName &&
                                  editFormik.errors.firstName
                                    ? "1px solid red"
                                    : "",
                              }}
                            />
                            {editFormik.touched.firstName &&
                              editFormik.errors.firstName && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.firstName}
                                </div>
                              )}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Last Name
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Your Last name"
                              size="lg"
                              name="lastName"
                              value={editFormik.values.lastName}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  editFormik.touched.lastName &&
                                  editFormik.errors.lastName
                                    ? "1px solid red"
                                    : "",
                              }}
                            />
                            {editFormik.touched.lastName &&
                              editFormik.errors.lastName && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.lastName}
                                </div>
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Email
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="email"
                              placeholder="Your email address"
                              size="lg"
                              name="email"
                              value={editFormik.values.email}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  editFormik.touched.email &&
                                  editFormik.errors.email
                                    ? "1px solid red"
                                    : "",
                              }}
                            />
                            {editFormik.touched.email &&
                              editFormik.errors.email && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.email}
                                </div>
                              )}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Phone
                            </FormLabel>
                            <div className="userphone">
                              <PhoneInput
                                country={"in"}
                                buttonStyle={{
                                  border:
                                    editFormik.touched.phone &&
                                    editFormik.errors.phone
                                      ? "1px solid red"
                                      : "",
                                }}
                                inputStyle={{
                                  border:
                                    editFormik.touched.phone &&
                                    editFormik.errors.phone
                                      ? "1px solid red"
                                      : "",
                                }}
                                onChange={(phone) => {
                                  setUser({ ...user, phone: phone });
                                  const phoneNumberWithPlus = `+${phone}`;

                                  editFormik.setFieldValue(
                                    "phone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={editFormik.values.phone}
                                onBlur={editFormik.handleBlur}
                                style={{ height: "37px" }}
                              />
                            </div>
                            {editFormik.touched.phone &&
                              editFormik.errors.phone && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.phone}
                                </div>
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Password
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="number"
                              placeholder="Your Password"
                              mb="24px"
                              size="lg"
                              name="password"
                              value={editFormik.values.password}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Date of Birth
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="date"
                              mb="24px"
                              size="lg"
                              name="dateofbirth"
                              value={editFormik.values.dateofbirth}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Address
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              size="lg"
                              placeholder="Your Address"
                              name="address"
                              value={editFormik.values.address}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  editFormik.touched.address &&
                                  editFormik.errors.address
                                    ? "1px solid red"
                                    : "",
                              }}
                            />
                            {editFormik.touched.address &&
                              editFormik.errors.address && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.address}
                                </div>
                              )}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Role
                            </FormLabel>
                            <select
                              className="form-control"
                              id="sel1"
                              name="role"
                              value={editFormik.values.role}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  editFormik.touched.role &&
                                  editFormik.errors.role
                                    ? "1px solid red"
                                    : "",
                              }}
                            >
                              <option disabled={true} value="">
                                Select Role
                              </option>
                              <option>User</option>
                              <option>Admin</option>
                              <option>Account Manager</option>
                              <option>Team Lead</option>
                              <option>Recruiter</option>
                              <option>Client</option>
                            </select>
                            {editFormik.touched.role &&
                              editFormik.errors.role && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.role}
                                </div>
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Aadhar card Number
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              mb="24px"
                              size="lg"
                              placeholder="Your Aadhar card Number"
                              name="aadharcardNumber"
                              value={editFormik.values.aadharcardNumber}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Pan card Number
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              mb="24px"
                              size="lg"
                              placeholder="Your Pan card Number"
                              name="pancardNumber"
                              value={editFormik.values.pancardNumber}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Bank Account Number
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="number"
                              mb="24px"
                              size="lg"
                              placeholder="Your Bank Account Number"
                              name="bankAccounNumber"
                              value={editFormik.values.bankAccounNumber}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              IFSC
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              mb="24px"
                              size="lg"
                              placeholder="Your IFSC Code"
                              name="ifscCode"
                              value={editFormik.values.ifscCode}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Bank Name
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              mb="24px"
                              size="lg"
                              placeholder="Your Bank Name"
                              name="bankName"
                              value={editFormik.values.bankName}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{ height: "37px" }}
                            />
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Offer Letter
                            </label>

                            <div class="file-dummy">
                              <div class="default">
                                <span class="drag">
                                  Drag and Drop Offer Letter
                                </span>
                                <br />
                                or you can <span class="browse">
                                  Browse
                                </span>{" "}
                                and find a file here
                              </div>

                              <input
                                type="file"
                                id="offerletter-file-input"
                                name="offerLetter"
                                onChange={(e) => {
                                  saveFile(e, "offerLetter");
                                }}
                                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                              />
                            </div>
                            {offerLetter ? (
                              <div
                                style={{
                                  width: "auto",
                                  height: "2rem",
                                  border: "1px solid grey",
                                  padding: "4px",
                                  paddingLeft: "16px",
                                }}
                                className="resumename"
                              >
                                {(() => {
                                  if (
                                    typeof offerLetter === "string" &&
                                    offerLetter.trim() !== "" &&
                                    offerLetter.trim() !== "Select File"
                                  ) {
                                    const nameParts = offerLetter.split("/");
                                    const lastName =
                                      nameParts[nameParts.length - 1];

                                    return lastName;
                                  } else if (offerLetter instanceof File) {
                                    const lastName =
                                      offerLetter.name.split(".")[0];

                                    return lastName;
                                  } else {
                                    return "Select File";
                                  }
                                })()}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Work Order
                            </label>
                            <div class="file-dummy">
                              <div class="default">
                                <span class="drag">
                                  Drag and Drop Work Order
                                </span>
                                <br />
                                or you can <span class="browse">
                                  Browse
                                </span>{" "}
                                and find a file here
                              </div>
                              <input
                                type="file"
                                id="workorder-file-input"
                                name="workOrder"
                                onChange={(e) => {
                                  saveFile(e, "workOrder");
                                }}
                                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                              />
                            </div>
                            {workOrder ? (
                              <div
                                style={{
                                  width: "auto",
                                  height: "2rem",
                                  border: "1px solid grey",
                                  padding: "4px",
                                  paddingLeft: "16px",
                                }}
                                className="resumename"
                              >
                                {(() => {
                                  if (
                                    typeof workOrder === "string" &&
                                    workOrder.trim() !== "" &&
                                    workOrder.trim() !== "Select File"
                                  ) {
                                    const nameParts = workOrder.split("/");
                                    const lastName =
                                      nameParts[nameParts.length - 1];

                                    return lastName;
                                  } else if (workOrder instanceof File) {
                                    const lastName =
                                      workOrder.name.split(".")[0];

                                    return lastName;
                                  } else {
                                    return "Select File";
                                  }
                                })()}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Other Documents
                            </label>

                            <div class="file-dummy">
                              <div class="default">
                                <span class="drag">
                                  Drag and Drop Other Documents
                                </span>
                                <br />
                                or you can <span class="browse">
                                  Browse
                                </span>{" "}
                                and find a file here
                              </div>
                              <input
                                type="file"
                                id="otherdocuments-file-input"
                                name="otherDocuments"
                                onChange={(e) => {
                                  saveFile(e, "otherDocuments");
                                }}
                                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                              />
                            </div>
                            {otherDocuments ? (
                              <div
                                style={{
                                  width: "auto",
                                  height: "2rem",
                                  border: "1px solid grey",
                                  padding: "4px",
                                  paddingLeft: "16px",
                                }}
                                className="resumename"
                              >
                                {(() => {
                                  if (
                                    typeof otherDocuments === "string" &&
                                    otherDocuments.trim() !== "" &&
                                    otherDocuments.trim() !== "Select File"
                                  ) {
                                    const nameParts = otherDocuments.split("/");
                                    const lastName =
                                      nameParts[nameParts.length - 1];

                                    return lastName;
                                  } else if (otherDocuments instanceof File) {
                                    const lastName =
                                      otherDocuments.name.split(".")[0];

                                    return lastName;
                                  } else {
                                    return "Select File";
                                  }
                                })()}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </Container>
                    </FormControl>
                    <Modal.Footer
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        className="cancel"
                        type="button"
                        onClick={handleEditClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        isLoading={loading}
                        className="btn_bg_color"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </TabPanels>
        </Tabs>
      </Box>
      <AccountPreviewModel
        accountPreview={userPreview}
        handleSubmissionPreviewClose={handleEditClose}
        objectURL={objectURL}
        displayAccount={user}
        selectedType={selectedType}
        type={"user"}
      />
    </Box>
  );
}

export default Organizationview;
