import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { City, State } from "country-state-city";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import "../../views/Dashboard/org.css";
import ParsedResumeDetails from "views/Dashboard/ParsedResumeDetails";
import { SelectPicker } from "rsuite";
import { useGetCandidateStatusMutation } from "../../redux/api/candidateStatus";

const EditCandidateModal = ({
  ViewEdit,
  handleEditClose,
  editFormik,
  saveFile,
  parseSummary,
  resumeFilePath,
  toggleResumeDetails,
  showResumeDetails,
  setParsedata,
  Country,
  note,
  displaycandidate,
  setSelectedCountry,
  setCandidate,
  applicationStatus,
  sourceData,
  candidateNote,
  candidateStatus,
  handleAddEducation,
  eductionFields,
  setEductionFields,
  handleFormChange,
  handleAddWork,
  workExperienceFields,
  handleFormWorkChange,
  setSelectedWorkCountry,
  handleFormWorkExpChange,
  setSelectedWorkState,
  setSelectedorkCity,
  handleAddReference,
  referenceFields,
  setReferenceFields,
  handleFormReferenceChange,
  setSendMail,
  sendMail,
  handleDelete,
  selectedCountry,
  selectedWorkCountry,
  selectedWorkState,
  selectedWorkCity,
  candidateLoading,
  setWorkexperienceFields,
  file,
  parsedata,
  setNote,
  setCandidateNote,
  getCandidatNotes,
  candidate,
  setSelectedCity,
  selectedCity,
  setSelectedState,
  selectedState,
  industries,
  parsedObjectives,
}) => {
  const [getCandidateStatusTrigger] = useGetCandidateStatusMutation();
  return (
    <>
      <div className="model-box-view">
        <Modal
          show={ViewEdit}
          onHide={handleEditClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          fullscreen={true}
          // style={{zIndex: 10000}}
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Edit Candidate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {resumeFilePath && (
                <Col lg={6} md={6}>
                  <div style={{ width: "100%" }}>
                    {/* Dispaly resume after parse resume on left side of form  but when file type is docx it cannot show*/}
                    {resumeFilePath && (
                      <div
                        style={
                          showResumeDetails
                            ? {
                                display: "flex",
                                zIndex: "2000",
                              }
                            : null
                        }
                      >
                        {showResumeDetails && (
                          <ParsedResumeDetails
                            resumeFilePath={resumeFilePath}
                            showResumeDetails={showResumeDetails}
                            toggleResumeDetails={toggleResumeDetails}
                            file={file}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              )}

              <Col lg={!resumeFilePath ? 12 : 6} md={6}>
               
                <form
                  id="addcandidate"
                  className="addcandidatemodelform"
                  onSubmit={editFormik.handleSubmit}
                >
                  <Container>
                  <div style={{display:"flex",justifyContent:"flex-end"}}>
                  {" "}
                  <Button
                    className="candi_btns"
                    
                    onClick={() => {
                      setCandidateNote({
                        ...candidateNote,
                        candidateId: candidate?._id,
                      });
                      setNote({
                        ...note,
                        candidateId: candidate._id,
                        clientId: candidate?.clientId?._id,
                      });
                      getCandidatNotes(candidate?._id);
                    }}
                  >
                    Notes ({candidate.noteCount ? candidate?.noteCount : 0})
                  </Button>
                </div>
                    <div style={{ width: "100%", marginBottom: "1rem" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          color: "#000000",
                        }}
                      >
                        Resume
                      </label>

                      <div className="file-dummy">
                        <div className="default">
                          <span className="drag">Drag and Drop Resume</span>
                          <br />
                          or you can <span className="browse">Browse</span> and
                          find a file here
                        </div>
                        <input
                          type="file"
                          id="candidate-file-input"
                          name="file"
                          onBlur={editFormik.handleBlur}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            editFormik.setFieldValue("file", file);
                            saveFile(e, "edit");
                          }}
                          accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "2rem",
                          border: "1px solid grey",
                          marginTop: "1rem",
                          padding: "4px",
                          paddingLeft: "16px",
                          border:
                            editFormik.touched.file && editFormik.errors.file
                              ? "1px solid red"
                              : "1px solid #ccc",
                        }}
                        className="resumename"
                      >
                        {editFormik.touched.file && editFormik.errors.file
                          ? editFormik.errors.file
                          : editFormik.values.file
                            ? editFormik.values.file.name ||
                              editFormik.values.fileName
                            : ""}
                      </div>
                    </div>

                    {/* {resumeFilePath && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    flexDirection={"row"}
                  >
                    <Button
                      onClick={toggleResumeDetails}
                      _hover={"none"}
                      background={"#023f61"}
                      color={"#fff"}
                    >
                      {showResumeDetails ? "Hide Resume" : "Show Resume"}
                    </Button>
                  </Box>
                )} */}

                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          First Name
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="First Name"
                          size="lg"
                          name="firstName"
                          value={editFormik.values.firstName}
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                firstname: e.target.value,
                              });
                          }}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.firstName &&
                              editFormik.errors.firstName
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.firstName &&
                          editFormik.errors.firstName && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.firstName}
                            </div>
                          )}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Last Name
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Last Name"
                          size="lg"
                          name="lastName"
                          value={editFormik.values.lastName}
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                lastname: e.target.value,
                              });
                          }}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.lastName &&
                              editFormik.errors.lastName
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.lastName &&
                          editFormik.errors.lastName && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.lastName}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Email
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Email"
                          size="lg"
                          name="email"
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                email: e.target.value,
                              });
                          }}
                          value={editFormik.values.email}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.email &&
                              editFormik.errors.email
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.email &&
                          editFormik.errors.email && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.email}
                            </div>
                          )}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Phone
                        </FormLabel>
                        <div className="candidatephone">
                          <PhoneInput
                            country={"in"}
                            containerStyle={{
                              border:
                                editFormik.touched.phone &&
                                editFormik.errors.phone
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                            value={editFormik.values.phone}
                            onChange={(phone) => {
                              const cleanedPhone = phone.replace(/\D/g, "");
                              const formattedPhone = `+${cleanedPhone.slice(
                                0,
                                2
                              )} ${cleanedPhone.slice(2)}`;

                              setCandidate({
                                ...candidate,
                                phone: formattedPhone,
                              });
                              setParsedata &&
                                setParsedata({
                                  ...parsedata,
                                  phone: formattedPhone,
                                });
                              const phoneNumberWithPlus = `+${phone}`;
                              editFormik.setFieldValue(
                                "phone",
                                phoneNumberWithPlus
                              );
                            }}
                          />
                          {editFormik.touched.phone &&
                            editFormik.errors.phone && (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.phone}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Candidate Designation
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="tel"
                          placeholder="Candidate Designation"
                          size="lg"
                          name="designation"
                          value={editFormik.values.designation}
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                designation: e.target.value,
                              });
                          }}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.designation &&
                              editFormik.errors.designation
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.designation &&
                          editFormik.errors.designation && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.designation}
                            </div>
                          )}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Application Status
                        </FormLabel>
                        <select
                          className="form-control"
                          name="status"
                          value={editFormik.values.status}
                          onChange={async (e) => {
                            editFormik.handleChange(e);
                            try {
                              const res = await getCandidateStatusTrigger({
                                applicationStatus: e.target.value,
                              }).unwrap();
                              console.log("hello res-------", res);
                              editFormik.setFieldValue("candidateStatus", res);
                            } catch (error) {
                              console.log("come------", error);
                            }
                          }}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.status &&
                              editFormik.errors.status
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        >
                          <option value="">Application Status</option>
                          {applicationStatus?.map((val) => (
                            <>
                              <option value={val?.CandidateApplicationStatus}>
                                {val?.CandidateApplicationStatus}
                              </option>
                            </>
                          ))}
                        </select>
                        {editFormik.touched.status &&
                          editFormik.errors.status && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.status}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={4} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Country
                        </FormLabel>
                        <div style={{ width: "100%", height: "37px" }}>
                          <Select
                            options={Country.getAllCountries()}
                            placeholder="Select Country"
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            name="country"
                            value={selectedCountry}
                            onChange={(item) => {
                              setSelectedCountry(item);
                              setCandidate({
                                ...candidate,
                                country: item.name,
                              });
                              setSelectedState(null);
                              setSelectedCity(null);
                              editFormik.setFieldValue("state", "");
                              editFormik.setFieldValue("city", "");
                              editFormik.setFieldValue("country", item.name);
                            }}
                            classNamePrefix={
                              editFormik.touched.country &&
                              editFormik.errors.country &&
                              "democlass"
                            }
                            styles={{
                              border:
                                editFormik.touched.country &&
                                editFormik.errors.country
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {editFormik.touched.country &&
                            editFormik.errors.country && (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.country}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} md={4} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          State
                        </FormLabel>

                        <Select
                          name="state"
                          options={State?.getStatesOfCountry(
                            selectedCountry?.isoCode
                          )}
                          placeholder="Select State"
                          getOptionLabel={(options) => {
                            return options["name"];
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          value={selectedState}
                          onChange={(item) => {
                            if (selectedState?.isoCode !== item.isoCode) {
                              setSelectedState(item);
                              setSelectedCity(null);

                              editFormik.setFieldValue("state", item.name);
                              editFormik.setFieldValue("city", "");
                            }
                          }}
                          classNamePrefix={
                            editFormik.touched.state &&
                            editFormik.errors.state &&
                            "democlass"
                          }
                          styles={{
                            border:
                              editFormik.touched.state &&
                              editFormik.errors.state
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.state && editFormik.errors.state ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {editFormik.errors.state}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={4} md={4} style={{ marginBottom: "24px" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          City
                        </FormLabel>
                        <Select
                          name="city"
                          options={City.getCitiesOfState(
                            selectedState?.countryCode,
                            selectedState?.isoCode
                          )}
                          placeholder="Select City"
                          getOptionLabel={(options) => {
                            return options["name"];
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          value={selectedCity}
                          onChange={(item) => {
                            setSelectedCity(item);
                            editFormik.setFieldValue("city", item.name);
                          }}
                          classNamePrefix={
                            editFormik.touched.city &&
                            editFormik.errors.city &&
                            "democlass"
                          }
                          styles={{
                            border:
                              editFormik.touched.city && editFormik.errors.city
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />

                        {editFormik.touched.city && editFormik.errors.city && (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {editFormik.errors.city}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Skill
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Skill"
                          size="lg"
                          name="skill"
                          value={editFormik.values.skill}
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                city: e.target.value,
                              });
                          }}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.skill &&
                              editFormik.errors.skill
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.skill &&
                          editFormik.errors.skill && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.skill}
                            </div>
                          )}
                      </Col>
                      <Col lg={3} md={3} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Experience (in years)
                        </FormLabel>
                        <Input
                          className="form-control"
                          name="experiance"
                          type="number"
                          placeholder="Experience"
                          value={editFormik.values.experiance}
                          onChange={editFormik.handleChange}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.experiance &&
                              editFormik.errors.experiance
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        ></Input>
                        {editFormik.touched.experiance &&
                          editFormik.errors.experiance && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.experiance}
                            </div>
                          )}
                      </Col>
                      <Col lg={3} md={3}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Industry
                        </FormLabel>

                        <select
                          className="form-control"
                          name="industry"
                          value={editFormik.values.industry}
                          onChange={editFormik.handleChange}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.industry &&
                              editFormik.errors.industry
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        >
                          <option disabled={true} value="">
                            Select Industry
                          </option>
                          {industries?.map((val) => (
                            <>
                              <option value={val?.industry}>
                                {val?.industry}
                              </option>
                            </>
                          ))}
                        </select>
                        {editFormik.touched.industry &&
                          editFormik.errors.industry && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.industry}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Date Of Birth
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="date"
                          placeholder="Date Of Birth"
                          size="lg"
                          name="dateofBirth"
                          value={editFormik.values.dateofBirth}
                          onChange={editFormik.handleChange}
                          onBlur={editFormik.handleBlur}
                          style={{ width: "100%", height: "37px" }}
                        />
                      </Col>
                      <Col lg={4} md={6} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Gender
                        </FormLabel>
                        <select
                          className="form-control"
                          name="gender"
                          value={editFormik.values.gender}
                          onChange={editFormik.handleChange}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.gender &&
                              editFormik.errors.gender
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                        {editFormik.touched.gender &&
                          editFormik.errors.gender && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.gender}
                            </div>
                          )}
                      </Col>
                      <Col lg={4} md={6} style={{ marginBottom: "2rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Notice period (in days)
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="number"
                          placeholder="Notice Period"
                          size="lg"
                          name="noticePeriod"
                          id="noticePeriod"
                          value={editFormik.values.noticePeriod}
                          onChange={editFormik.handleChange}
                          onBlur={editFormik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.noticePeriod &&
                              editFormik.errors.noticePeriod
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {editFormik.touched.noticePeriod &&
                          editFormik.errors.noticePeriod && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.noticePeriod}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Current Candidate CTC"
                            name="currentCandidateCTC"
                            value={editFormik.values.currentCandidateCTC}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              height: "37px",
                            }}
                          />

                          <select
                            className="form-control"
                            name="currentCandidateCTCType"
                            value={editFormik.values.currentCandidateCTCType}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              height: "37px",
                              fontSize: "14px",
                              width: "auto",
                            }}
                          >
                            <option disabled={true} value="">
                              Select
                            </option>
                            <option>Monthly</option>
                            <option>Annual Salary</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Expected Candidate CTC"
                            name="expectedCandidateCTC"
                            value={editFormik.values.expectedCandidateCTC}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              height: "37px",
                            }}
                          />
                          <select
                            className="form-control"
                            name="expectedCandidateCTCType"
                            value={editFormik.values.expectedCandidateCTCType}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              height: "37px",
                              fontSize: "14px",
                              width: "auto",
                            }}
                          >
                            <option disabled={true} value="">
                              Select
                            </option>
                            <option>Monthly</option>
                            <option>Annual Salary</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Aadhar Card Number
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Aadhar Card Number"
                          mb="24px"
                          size="lg"
                          name="AadharcardNumber"
                          value={editFormik.values.AadharcardNumber}
                          onChange={editFormik.handleChange}
                          style={{ width: "100%", height: "37px" }}
                        />
                      </Col>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          PAN Card Number
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="tel"
                          placeholder="PAN Card Number"
                          mb="24px"
                          size="lg"
                          name="PancardNumber"
                          value={editFormik.values.PancardNumber}
                          onChange={editFormik.handleChange}
                          style={{ width: "100%", height: "37px" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Address
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Address"
                          mb="24px"
                          size="lg"
                          name="address"
                          value={editFormik.values.address}
                          onChange={(e) => {
                            editFormik.handleChange(e);
                            setParsedata &&
                              setParsedata({
                                ...parsedata,
                                address: e.target.value,
                              });
                          }}
                          style={{ width: "100%", height: "37px" }}
                        />
                      </Col>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Pincode
                        </FormLabel>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Pincode"
                          mb="24px"
                          size="lg"
                          name="pincode"
                          value={editFormik.values.pincode}
                          onChange={editFormik.handleChange}
                          style={{ width: "100%", height: "37px" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Source
                        </FormLabel>
                        <select
                          className="form-control"
                          name="source"
                          value={editFormik.values.source}
                          onChange={editFormik.handleChange}
                          style={{
                            width: "100%",
                            height: "37px",
                            border:
                              editFormik.touched.source &&
                              editFormik.errors.source
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        >
                          <option disabled={true} value="">
                            Select
                          </option>
                          {sourceData.map((source, index) => {
                            return (
                              <option key={index}>{source?.source}</option>
                            );
                          })}
                        </select>
                        {editFormik.touched.source &&
                          editFormik.errors.source && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.source}
                            </div>
                          )}
                      </Col>
                      <Col lg={6} md={6}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Candidate Status
                        </FormLabel>
                        {/* <Input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Pincode"
                          mb="24px"
                          size="lg"
                          name="candidateStatus"
                          value={editFormik.values.candidateStatus}
                          style={{ width: "100%", height: "37px" }}
                        /> */}
                        <select
                          className="form-control"
                          name="candidateStatus"
                          value={editFormik.values.candidateStatus}
                          // onChange={editFormik.handleChange}
                          disabled={true}
                          style={{ width: "100%", height: "37px" }}
                        >
                          <option disabled={true} value="">
                            Candidate Status
                          </option>
                          {candidateStatus?.map((val) => (
                            <>
                              <option value={val?.candidateStatus}>
                                {val?.candidateStatus}
                              </option>
                            </>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </Container>
                  <br />
                  {/* For Education Details */}
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          padding: "0.3rem",
                          paddingBottom: "15px",
                        }}
                        className="usertitle"
                      >
                        Education Details
                      </h1>
                      <span
                        style={{
                          display: "flex",
                          width: "76px",
                          height: "34px",
                          fontFamily: "Roboto",
                          color: "#627D8C",
                          fontSize: "20px",
                          border: "1.2px solid #87A6B7",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleAddEducation();
                        }}
                      >
                        Add
                      </span>
                    </div>
                    {eductionFields.map((education, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }}>
                          <div className="close_icon">
                            <CloseButton
                              onClick={() => {
                                let filterData = eductionFields.filter(
                                  (res, In) => In !== index
                                );
                                setEductionFields(filterData);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #d3d1d1",
                              width: "100%",
                              height: "auto",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <Container>
                              <form>
                                <Row>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Institute Name
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Institute Name"
                                      mb="24px"
                                      size="lg"
                                      name="institutename"
                                      value={
                                        education?.institutename
                                          ? education?.institutename
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Degree
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Degree"
                                      mb="24px"
                                      size="lg"
                                      name="degree"
                                      value={
                                        education?.degree
                                          ? education?.degree
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col
                                    lg={4}
                                    md={4}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Category
                                    </label>
                                    <select
                                      className="form-control"
                                      name="category"
                                      value={education.category}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    >
                                      <option disabled={true} value="">
                                        Select
                                      </option>
                                      <option>High School</option>
                                      <option>Military Service</option>
                                      <option>Vocational School</option>
                                      <option>Associate</option>
                                      <option>Pre-Bachelors</option>
                                      <option>Bachelors</option>
                                      <option>Post-Bachelors</option>
                                      <option>Masters</option>
                                      <option>Doctorate</option>
                                      <option>Other</option>
                                    </select>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Passing Year
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="number"
                                      placeholder=" Passing Year"
                                      mb="24px"
                                      size="lg"
                                      name="endDate"
                                      value={education.endDate}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      CGPA/Percentage
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="CGPA/Percentage"
                                      mb="24px"
                                      size="lg"
                                      name="percentage"
                                      value={
                                        education?.percentage
                                          ? education?.percentage
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                </Row>
                              </form>
                            </Container>
                          </div>
                          <br />
                        </div>
                      );
                    })}
                  </Container>
                  {/* For Work Experience Details */}
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          padding: "0.3rem",
                          paddingBottom: "15px",
                        }}
                        className="usertitle"
                      >
                        Work Experience Details
                      </h1>
                      <span
                        style={{
                          display: "flex",
                          width: "76px",
                          height: "34px",
                          fontFamily: "Roboto",
                          color: "#627D8C",
                          fontSize: "20px",
                          border: "1.2px solid #87A6B7",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleAddWork();
                        }}
                      >
                        Add
                      </span>
                    </div>
                    {workExperienceFields.map((work, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }}>
                          <div className="close_icon">
                            <CloseButton
                              onClick={() => {
                                let filterData = workExperienceFields.filter(
                                  (res, In) => In !== index
                                );
                                setWorkexperienceFields(filterData);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #d3d1d1",
                              width: "100%",
                              height: "auto",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <Container>
                              <form id="workExperienceFields">
                                <Row>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Company Name
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="companyname"
                                      mb="24px"
                                      size="lg"
                                      name="companyname"
                                      value={
                                        work?.companyname
                                          ? work?.companyname
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleFormWorkChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Designation
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Designation"
                                      mb="24px"
                                      size="lg"
                                      name="designation"
                                      value={
                                        work?.designation
                                          ? work?.designation
                                          : ""
                                      }
                                      onChange={(event) =>
                                        handleFormWorkChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Start Date
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="month"
                                      placeholder="Start Date"
                                      mb="24px"
                                      size="lg"
                                      name="startDate"
                                      value={work.startDate}
                                      onChange={(event) =>
                                        handleFormWorkChange(index, event)
                                      }
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      End Date
                                    </label>
                                    {work.endDate?.toLowerCase() == "present" ||
                                    work.endDate?.toLowerCase() == "ongoing" ||
                                    work.endDate?.toLowerCase() == "current" ? (
                                      <div>
                                        <Checkbox
                                          mt={1}
                                          defaultChecked
                                          readOnly
                                        >
                                          Present
                                        </Checkbox>
                                      </div>
                                    ) : (
                                      <Input
                                        variant="auth"
                                        fontSize="sm"
                                        type="month"
                                        placeholder="End Date"
                                        mb="24px"
                                        size="lg"
                                        name="endDate"
                                        value={work.endDate}
                                        onChange={(event) =>
                                          handleFormWorkChange(index, event)
                                        }
                                        style={{
                                          width: "100%",
                                          height: "37px",
                                        }}
                                      />
                                    )}
                                  </Col>
                                  <Col
                                    lg={4}
                                    md={4}
                                    style={{ marginBottom: "2rem" }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Country
                                    </label>
                                    <Select
                                      name="country"
                                      options={Country.getAllCountries()}
                                      placeholder="Select Country"
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={
                                        work.country
                                          ? {
                                              name: work.country,
                                              isoCode: work.countryCode,
                                            }
                                          : null
                                      }
                                      onChange={(value, action) => {
                                        setSelectedWorkCountry(value);
                                        handleFormWorkExpChange(
                                          index,
                                          value,
                                          action
                                        );
                                      }}
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col
                                    lg={4}
                                    md={4}
                                    style={{ marginBottom: "2rem" }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      State
                                    </label>
                                    <Select
                                      name="state"
                                      options={State.getStatesOfCountry(
                                        work.countryCode
                                      )}
                                      placeholder="Select State"
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={
                                        work.state
                                          ? {
                                              name: work.state,
                                              isoCode: work.stateCode,
                                            }
                                          : null
                                      }
                                      onChange={(value, action) => {
                                        handleFormWorkExpChange(
                                          index,
                                          value,
                                          action
                                        );
                                      }}
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    lg={4}
                                    md={4}
                                    style={{ marginBottom: "2rem" }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      City
                                    </label>
                                    <Select
                                      name="city"
                                      options={City.getCitiesOfState(
                                        work.countryCode,
                                        work.stateCode
                                      )}
                                      placeholder="Select City"
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={
                                        work.city ? { name: work.city } : null
                                      }
                                      onChange={(value, action) => {
                                        handleFormWorkExpChange(
                                          index,
                                          value,
                                          action
                                        );
                                      }}
                                      style={{ width: "100%", height: "37px" }}
                                    />
                                  </Col>
                                  <Col></Col>
                                  <Col></Col>
                                  {work?.responsibilities && (
                                    <>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        Responsibility :-
                                      </label>

                                      <ul>
                                        {work?.responsibilities?.map((res) => {
                                          return (
                                            <li
                                              style={{ fontSize: "0.9rem" }}
                                              key={res}
                                            >
                                              {res}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  )}
                                </Row>
                              </form>
                            </Container>
                          </div>
                          <br />
                        </div>
                      );
                    })}
                  </Container>
                  {/* For Reference Details */}
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          padding: "0.3rem",
                          paddingBottom: "15px",
                        }}
                        className="usertitle"
                      >
                        Reference Details
                      </h1>
                      <span
                        style={{
                          display: "flex",
                          width: "76px",
                          height: "34px",
                          fontFamily: "Roboto",
                          color: "#627D8C",
                          fontSize: "20px",
                          border: "1.2px solid #87A6B7",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleAddReference();
                        }}
                      >
                        Add
                      </span>
                    </div>
                    {referenceFields.map((reference, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }}>
                          <div className="close_icon">
                            <CloseButton
                              onClick={() => {
                                let filterData = referenceFields.filter(
                                  (res, In) => In !== index
                                );
                                setReferenceFields(filterData);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #d3d1d1",
                              width: "100%",
                              height: "auto",
                              borderRadius: "5px",
                              padding: "10px",
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Container>
                              <form>
                                <Row>
                                  <Col
                                    lg={4}
                                    md={4}
                                    style={{ marginBottom: "2rem" }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Reference Type
                                    </label>
                                    <select
                                      className="form-control"
                                      name="referenceType"
                                      value={reference.referenceType}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{
                                        height: "37px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <option disabled={true} value="">
                                        Select
                                      </option>
                                      <option>Other</option>
                                      <option>Personal</option>
                                      <option>Professional</option>
                                    </select>
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Reference Name
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Reference Name"
                                      mb="24px"
                                      size="lg"
                                      name="referenceName"
                                      value={reference.referenceName}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{ height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Company Name
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Company Name"
                                      mb="24px"
                                      size="lg"
                                      name="companyName"
                                      value={reference.companyName}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{ height: "37px" }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Designation
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Designation"
                                      mb="24px"
                                      size="lg"
                                      name="designation"
                                      value={reference.designation}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{ height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Email
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Email"
                                      mb="24px"
                                      size="lg"
                                      name="email"
                                      value={reference.email}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{ height: "37px" }}
                                    />
                                  </Col>
                                  <Col lg={4} md={4}>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                        color: "#000000",
                                      }}
                                    >
                                      Phone
                                    </label>
                                    <Input
                                      variant="auth"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Phone"
                                      mb="24px"
                                      size="lg"
                                      name="phone"
                                      value={reference.phone}
                                      onChange={(event) =>
                                        handleFormReferenceChange(index, event)
                                      }
                                      style={{ height: "37px" }}
                                    />
                                  </Col>
                                </Row>
                              </form>
                            </Container>
                          </div>
                        </div>
                      );
                    })}
                  </Container>
                  {setSendMail && (
                    <>
                      <Container>
                        <Box display="flex" justifyContent="end" mt={5} mb={5}>
                          <Checkbox
                            isChecked={sendMail}
                            onChange={() => setSendMail(!sendMail)}
                          >
                            <span style={{ fontSize: "14px" }}>
                              You Want to send mail to Candidate
                            </span>
                          </Checkbox>
                        </Box>
                        {parseSummary && <Box mb={1}>{parseSummary}</Box>}
                        {parsedObjectives && (
                          <Box mt={1}>
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.3rem",
                                padding: "0.3rem",
                                paddingBottom: "5px",
                              }}
                              className="usertitle"
                            >
                              Objectives
                            </h1>
                            <ul style={{ padding: "0.5rem" }}>
                              {parsedObjectives?.map((res) => {
                                return <li key={res}>{res}</li>;
                              })}
                            </ul>
                          </Box>
                        )}
                      </Container>
                    </>
                  )}

                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="cancel"
                      type="button"
                      onClick={handleEditClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="bg_color"
                      color={"#fff"}
                      type="submit"
                      isLoading={candidateLoading}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EditCandidateModal;
