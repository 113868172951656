import {
  Button,
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import NotFound from "assets/svg/not-found.svg";
import Searchicon from "assets/svg/search-icon.svg";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "views/Dashboard/Loader";
import PopoverSearchInput from "./PopoverSearchInput";
import axios from "axios";
import config from "../../config";
import PopoverDropDownSearchInput from "components/SearchCandidateModel/PopoverDropDownSearchInput";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CommonPagination from "../../components/CommonPagination/CommonPagination";

const SearchJobModel = (props) => {
  const {
    searchJobModel,
    handleCreateSubmissionClose,
    searchjob,
    setSearchJob,
    filterJobpostData,
    loading,
    jobposts,
   setTotalPages,
    setSubmission,
    setJobScreeningId,
    Setjobtitle,
    handleSubmitSubmissionShow,
    rowsPerSubmissionPage,
    submissionpage,
    handleChangeSubmissionPage,
    handleChangeRowsPerSubmissionPage,
    getJobpostdata,
    submission,
    jobtitle,
    clearFilter,
    searchJobTitle,
    setSearchJobTitle,
    jobSearchActiveField,
    setJobSearchActiveField,
    searchJobContactName,
    setSearchJobContactName,
    searchJobClient,
    setSearchJobClient,
    searchJobCity,
    setSearchJobCity,
    candidateId,

    setSearchJobOwner,
    searchJobOwner,
  } = props;
  const [activePopover, setActivePopover] = useState(null);
  const {userNames} = useSelector((state)=>state.usersSlice)
  const openPopover = (id) => {
    if (activePopover === id) {
      setActivePopover(null); // Toggle the current popover
    } else {
      setActivePopover(id);
    }
  };

  const isPopoverOpen = (id) => activePopover === id;

  const handleCheckSubmission = async (jobId, clientId) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getSubmissionByJobAndCandidateId?jobId=${jobId}&clientId=${clientId}&candidateId=${candidateId || ""}`
      );
      if (response.data.status === "success") {
        if (response.data.data) {
          return response.data.data;
        } else {
          console.log("No matching records found");
        }
      } else {
        console.log("API returned an error:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [account, setAccount] = useState([]);
  const getaccountsdata = () => {
    axios
      .get(`${config?.apiUrl}/api/get_all_clients`)
      .then((response) => {
        const accountsData = response.data.data;
        setAccount(accountsData);
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  useEffect(() => {
    getaccountsdata();
  }, []);


  return (
    <React.Fragment>
      <Modal
        show={searchJobModel}
        onHide={handleCreateSubmissionClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="usertitle">
            Search Job
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              filterJobpostData(searchjob, 0, 10, "", true);
            }}
            style={{ paddingBottom: "1rem" }}
          >
            <div
              style={{
                width: "500px",
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              <InputGroup>
                <InputLeftElement
                  children={
                    <img
                      src={Searchicon}
                      width="20px"
                      height="20px"
                      style={{ cursor: "pointer" }}
                    />
                  }
                  onClick={() => {
                    filterJobpostData(searchjob, 0, 10, "", true);
                  }}
                />
                <Input
                  type="text"
                  id="searchjob"
                  placeholder="Search Job Title, Location, Client..."
                  value={searchjob}
                  onChange={(e) => {
                    setSearchJob(e.target.value);
                  }}
                  style={{
                    height: "37px",
                    padding: "9px",
                    paddingLeft: "3rem",
                  }}
                />
                {searchjob.length !== 0 && (
                  <InputRightElement
                    children={
                      <CloseButton
                        size="md"
                        sx={{
                          background: "#fff",
                          color: "rgb(123, 154, 170)",
                        }}
                        _hover={{ background: "" }}
                        _focus={{ boxShadow: "none" }}
                      />
                    }
                    onClick={() => {
                      setSearchJob("");
                      // clearFilter();
                    }}
                  />
                )}
              </InputGroup>
              {/* {(jobSearchActiveField?.name || searchjob) && ( */}
              <Button
                className="bg_color"
                onClick={() => clearFilter()}
                style={{
                  color: "white",
                  border: "1px solid #d7d7d7",
                  fontWeight: "500",
                  letterSpacing: "1px",
                  fontSize: "14px",
                }}
              >
                Reset
              </Button>
              {/* )} */}
            </div>
          </form>
          <div style={{ width: "100%" }}>
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <TableContainer style={{ maxHeight: 680 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Job Title
                        <PopoverSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"jobTitle"}
                          filterJobpostData={filterJobpostData}
                          page={submissionpage}
                          rowsPerPage={rowsPerSubmissionPage}
                          inputId={"jobtitle"}
                          inputPlaceholder={"Search Title..."}
                          inputValue={searchJobTitle}
                          setInputValue={setSearchJobTitle}
                          setInputActiveValue={setJobSearchActiveField}
                        />
                      </TableCell>
                      <TableCell>
                        Location
                        <PopoverSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"location"}
                          filterJobpostData={filterJobpostData}
                          page={submissionpage}
                          rowsPerPage={rowsPerSubmissionPage}
                          inputId={"city"}
                          inputPlaceholder={"Search Location..."}
                          inputValue={searchJobCity}
                          setInputValue={setSearchJobCity}
                          setInputActiveValue={setJobSearchActiveField}
                        />
                      </TableCell>
                      <TableCell>
                        Client
                        {searchJobClient !== undefined &&
                          setSearchJobClient !== undefined && (
                            <PopoverDropDownSearchInput
                              setActivePopover={setActivePopover}
                              isPopoverOpen={isPopoverOpen}
                              openPopover={openPopover}
                              filedName={"client"}
                              filterJobpostData={filterJobpostData}
                              page={submissionpage}
                              rowsPerPage={rowsPerSubmissionPage}
                              inputId={"clientName"}
                              inputPlaceholder={"Search Client..."}
                              inputValue={searchJobClient}
                              setInputValue={setSearchJobClient}
                              setInputActiveValue={setJobSearchActiveField}
                              allUsers={account}
                            />
                          )}
                      </TableCell>
                      <TableCell>
                        Contact Name
                        {searchJobContactName !== undefined &&
                          setSearchJobContactName !== undefined && (
                            <PopoverSearchInput
                              setActivePopover={setActivePopover}
                              isPopoverOpen={isPopoverOpen}
                              openPopover={openPopover}
                              filedName={"contactName"}
                              filterJobpostData={filterJobpostData}
                              page={submissionpage}
                              rowsPerPage={rowsPerSubmissionPage}
                              inputId={"clientContactName"}
                              inputPlaceholder={"Search Contact Name..."}
                              inputValue={searchJobContactName}
                              setInputValue={setSearchJobContactName}
                              setInputActiveValue={setJobSearchActiveField}
                            />
                          )}
                      </TableCell>
                      <TableCell>
                        Job Owner Name
                        <PopoverDropDownSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"job_ownerName"}
                          filterJobpostData={filterJobpostData}
                          page={submissionpage}
                          rowsPerPage={rowsPerSubmissionPage}
                          inputId={"job_ownerName"}
                          inputPlaceholder={"Search owner..."}
                          inputValue={searchJobOwner}
                          setInputValue={setSearchJobOwner}
                          setInputActiveValue={setJobSearchActiveField}
                          allUsers={userNames}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Loader />
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {jobposts?.length === 0 ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={NotFound}
                                    alt="NotFound"
                                    style={{ width: "16rem" }}
                                  />
                                  <p>No Records Found! Please try again....</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {jobposts &&
                              jobposts?.map((value, index) => (
                                <TableRow key={value._id}>
                                  <TableCell
                                    onClick={async () => {
                                      const jobId = value?._id;
                                      const clientId = value?.clientId?._id;
                                      if (candidateId) {
                                        const data =
                                          await handleCheckSubmission(
                                            jobId,
                                            clientId
                                          );
                                        setSubmission({
                                          ...data,
                                          contactPerson_id:value?.clientContactName_Id,
                                          isStatusChange: true,
                                        });
                                   if(!data){
                                    setSubmission({
                                      ...submission,
                                      jobId: value?._id,
                                      Questions: value?.question,
                                      skill: value?.skill,
                                      clientId: value?.clientId?._id,
                                      clientemail: value?.clientId?.email,
                                      submitterName:
                                        value?.clientContactName,
                                        contactPerson_id:value?.clientContactName_Id,
                                    });
                                   }
                                       
                                        handleSubmitSubmissionShow(data?._id);
                                      } else {
                                        setSubmission({
                                          ...submission,
                                          jobId: value?._id,
                                          Questions: value?.question,
                                          skill: value?.skill,
                                          clientId: value?.clientId?._id,
                                          clientemail: value?.clientId?.email,
                                          submitterName:
                                            value?.clientContactName,
                                            contactPerson_id:value?.clientContactName_Id
                                        });
                                        handleSubmitSubmissionShow();
                                      }

                                      setJobScreeningId(value?._id);
                                      Setjobtitle({
                                        ...jobtitle,
                                        jbt: value?.jobtitle,
                                      });
                                      handleCreateSubmissionClose();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {value?.jobtitle ?? "N/A"}
                                  </TableCell>
                                  <TableCell>{value?.city ?? "N/A"}</TableCell>
                                  <TableCell>
                                    {value?.clientId?.accountName ?? "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {value?.clientContactName_data?.accountOwnerName
                                      ? value?.clientContactName_data?.accountOwnerName
                                      : "N/A"}
                                  </TableCell>
                                  {!value?.job_owener_data?.firstName ||
                                  !value?.job_owener_data?.lastName ? (
                                    "N/A"
                                  ) : (
                                    <TableCell>{`${value?.job_owener_data?.firstName} ${value?.job_owener_data?.lastName}`}</TableCell>
                                  )}
                                </TableRow>
                              ))}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonPagination
              rowsPerPage={rowsPerSubmissionPage}
              page={submissionpage}
              totalPages={Math.ceil(jobposts?.totalJobPosts / rowsPerSubmissionPage)}
              handleChangePage={handleChangeSubmissionPage}
              handleChangeRowsPerPage={handleChangeRowsPerSubmissionPage}
              count={jobposts?.totalJobPosts || 10}
            />
            </Paper>
          </div>
          <br />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SearchJobModel;
